<template>
  <div class="navbar-wrapper" id="application-header">
    <div @click="layOutChange">
      <PvMessage v-if="showExpireWarning" severity="warn" class="header-msg" icon="none">{{ expireMessage }}</PvMessage>
      <PvMessage v-if="refreshedDueToError" severity="success" class="header-msg" icon="none">
        {{ $t('navigation.refreshed-due-to-error-msg') }}</PvMessage>
      <PvMessage v-if="showSettings && relevantInvoiceStatus == 1" severity="success" class="header-msg" icon="none">
        {{ $t('navigation.new-invoice-long') }}</PvMessage>
      <PvMessage v-if="showSettings && relevantInvoiceStatus == 3" severity="error" class="header-msg" icon="none">
        {{ $t('navigation.unpaid-invoice-long') }}</PvMessage>
      <PvMessage v-if="showSettings && relevantInvoiceStatus == 4" severity="error" class="header-msg" icon="none">
        {{ $t('navigation.overdue-invoice-long') }}</PvMessage>
    </div>
    <div class="container">
      <nav v-if="!isAgentLogin" class="navbar">
        <div class="navbar-brand">
          <!--logo-->
          <div class="brand">
            <router-link :to="'/'">
              <img src="@/assets/simTree_logotext_white_bg.svg" class="no_highlights loggo" alt="logo" />
            </router-link>
          </div>
          <!--menu items-->
          <div>
            <a v-if="isNormalLogin" class="menu-item" :class="[{ 'active': isPage('portfolio') }]"
              @click="goToView('portfolio')">
              {{ $t('navigation.home') }}
            </a>
            <a v-if="isNormalLogin && !forecastIsAvailable" class="menu-item">
              {{ $t('navigation.loading') }}...
            </a>
            <a v-if="showForecast" class="menu-item" :class="[{ 'active': isPage('forecast') }]"
              @click="goToView('forecast')">
              {{ $t('navigation.forecast') }}
            </a>
            <a v-if="showSchedule" class="menu-item" :class="[{ 'active': isPage('schedule') }]"
              @click="goToView('schedule')">
              {{ $t('navigation.schedule') }}
            </a>
            <a v-if="showSchedule" class="menu-item" :class="[{ 'active': isPage('employees') }]"
              @click="goToView('employees')">
              {{ $t('navigation.employees') }}
            </a>
            <a v-if="showReport" class="menu-item" :class="[{ 'active': isPage('report') }]" @click="goToView('report')">
              {{ $t('navigation.report') }}
            </a>
            <a v-if="showSettings" class="menu-item" :class="[{ 'active': isPage('settings') }]"
              @click="goToView('settings')">
              {{ $t('navigation.settings') }}
            </a>
          </div>
        </div>

        <PvMenubar :model="userMenuItems">
          <template #item="{ item, props, hasSubmenu }">
            <div v-if="item.name === 'invoice-notif'" @click="checkInvoiceStatus">
              <p v-if="showSettings && relevantInvoiceStatus == 1"
                class="is-size-6 no_highlights invoice-info invoice-color-info"
                v-tooltip="$t('navigation.new-invoice-long')">{{ $t('navigation.new-invoice') }}</p>
              <p v-if="showSettings && relevantInvoiceStatus == 3"
                class="is-size-6 no_highlights invoice-info invoice-color-due"
                v-tooltip="$t('navigation.unpaid-invoice-long')">{{ $t('navigation.unpaid-invoice') }}</p>
              <p v-if="showSettings && relevantInvoiceStatus == 4"
                class="is-size-6 no_highlights invoice-info invoice-color-overdue"
                v-tooltip="$t('navigation.overdue-invoice-long')">{{ $t('navigation.overdue-invoice') }}</p>
            </div>
            <p v-else-if="item.name === 'supervisor' && supervisorInfo"
              v-tooltip.bottom="$t('navigation.supervior-info-tt')" class="is-size-6 supervior-info">{{
                $t('navigation.published-only') }}</p>
            <i v-else-if="item.name === 'timezone' && showCurrentTimezone" class="pi pi-clock"
              style="margin-right: 12px;" v-tooltip.bottom=currentTimezone></i>
            <i v-else-if="item.name === 'filter' && showEmpFilter" class="pi pi-filter"
              style="margin-right: 4px; cursor: pointer" v-tooltip.bottom="$t('schedule.filter-tt')"
              @click="scheduleFilter.show = !scheduleFilter.show"></i>
            <p v-else-if="item.name === 'filter-msg' && showEmpFilter" class="is-size-6 filter-info">{{
              scheduleFilter.filterMsg }}</p>
            <p v-else-if="item.name === 'read-only' && showReadOnlyInfo" class="is-size-6 supervior-info">{{
              $t('navigation.read-only') }}</p>
            <div v-else-if="item.name === 'affinity' && showAffintyDropDown" class="affinity-info" disabled>
              <PvDropdown v-model="scheduleFilter.affinity" :options="affinitySelection()" optionValue="id"
                optionLabel="name" @change="filterChange" :pt="{
                  root: { style: { borderRadius: '2rem', maxHeight: '600px' } },
                  input: { style: { height: '40px', padding: '0.5rem' } },
                  item: { style: { padding: '0.25rem 0.5rem', margin: '0.25rem' } },
                }">
                <template #value="slotProps">
                  <span> {{ getAffinityText() }}</span>
                </template>
              </PvDropdown>
            </div>
            <p v-else-if="item.name === 'call-center' && isCurrentCCSelected" class="is-size-6 call-center"
              style="white-space: nowrap;">{{ currentCC.name }}</p>
            <p v-else-if="item.name === 'user'" style="cursor: pointer;">
              <span>{{ item.label }}</span>
              <span class="pi pi-fw pi-angle-down" />
            </p>
            <p v-if="item.name === 'admin' && !isSuperAdmin && currentUser !== null && currentUser.hasAccessSettings && !currentUser.isSupervisor"
              @click="goToView(item.route)" style="padding: 0 1rem 0 0.25rem">
              <span>{{ item.label }}</span>
            </p>
            <p v-if="item.name === 'invoice' && !isSuperAdmin && currentUser !== null && currentUser.hasAccessSettings && !currentUser.isSupervisor"
              @click="goToView(item.route)" style="padding: 0 1rem 0 0.25rem">
              <span>{{ item.label }}</span>
            </p>
            <p v-if="item.name === 'security' && currentUser !== null" @click="goToView(item.route)"
              style="padding: 0 1rem 0 0.25rem">
              <span>{{ item.label }}</span>
            </p>
            <p v-if="item.name === 'avatar' && currentUser !== null" @click="activeSelectAvatar = true"
              style="padding: 0 1rem 0 0.25rem">
              <span>{{ item.label }}</span>
            </p>
            <p v-if="item.name === 'guide' && !isSuperAdmin" @click="userGuide.display = !userGuide.display"
              style="padding: 0 1rem 0 0.25rem">
              <span>{{ item.label }}</span>
            </p>
            <p v-else-if="item.name === 'logout'" @click="logOut()" style="padding: 0 1rem 0 0.25rem">
              <span>{{ item.label }}</span>
            </p>
            <p v-else-if="item.name === 'language'" style="padding: 0 1rem 0 0.25rem">
              <span>{{ item.label }}</span>
            </p>
            <p v-else-if="item.name === 'clear-cookies' && isPage('portfolio')" @click="clearLocalStorage()"
              style="padding: 0 1rem 0 0.25rem">
              <span>{{ item.label }}</span>
            </p>
            <p v-else-if="!item.name" @click="setLanguage(item)" style="padding: 0 0.5rem 0 0.25rem">{{ item }}</p>
          </template>
        </PvMenubar>
      </nav>
    </div>
    <!-- <RawData class :data="affinitySelection()" :title="'affinitySelection'" /> -->
    <Modal :active=activeSelectAvatar :title="$t('navigation.avatar-setting')" v-on:close="activeSelectAvatar = false">
      <template v-slot:body>
        <AvatarSelector ref="avatarSelector" :user="currentUser" />
      </template>
      <template v-slot:footer>
        <Button type="secondary" @click="activeSelectAvatar = false">{{ $t('global.cancel') }}</Button>
        <Button type="black-button" @click="okSelectAvatar">{{ $t('global.ok') }}</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import RawData from "@/components/Base/RawData.vue";
import AvatarSelector from "@/components/Settings/AvatarSelector.vue";

import { getSupportedCultures, loadLanguageAsync } from '@/i18n.js';
import * as dnLocalStorage from '@/js/dn-localStorage.js';
import * as dn_schedulehelper from '@/js/dn-schedule-helper.js';
import Icon from "@/components/Base/Icon.vue";
import { EventBus } from "@/js/dn-event-bus.js";
import { useDataStore } from "@/stores/dataStore.js";
import { useFileImportStore } from "@/stores/fileImportStore.js";
import { useForecastStore } from "@/stores/forecastStore";
import { useScheduleStore } from "@/stores/scheduleStore.js";
import Modal from "@/components/Base/Modal.vue";
import Button from "@/components/Base/Button.vue";
import { saveUsers } from "@/model/dn-user";

export default {
  // @ts-ignore
  components: { Icon, RawData, Modal, Button, AvatarSelector },
  name: "Header",

  data() {
    return {
      languages: getSupportedCultures(),
      activeSelectAvatar: false,
      userMenuItems: [
        {
          name: 'invoice-notif',
        },
        {
          name: 'supervisor',
        },
        {
          name: 'timezone',
        },
        {
          name: 'filter',
        },
        {
          name: 'filter-msg',
        },
        {
          name: 'read-only',
        },
        {
          name: 'affinity'
        },
        {
          name: 'call-center',
        },
        {
          name: 'user',
          label: this.$t('navigation.user'),
          items: [
            {
              name: 'admin',
              label: this.$t('navigation.admin'),
              route: 'admin'
            },
            {
              name: 'invoice',
              label: this.$t('navigation.invoice'),
              route: 'invoice'
            },
            {
              name: 'security',
              label: this.$t('navigation.security-settings'),
              route: 'user'
            },
            {
              name: 'avatar',
              label: this.$t('navigation.avatar')
            },
            {
              name: 'guide',
              label: this.$t('navigation.user-guide')
            },
            {
              name: 'logout',
              label: this.$t('navigation.log-out')
            },
            {
              name: 'language',
              label: this.$t('navigation.language'),
              items: getSupportedCultures()
            },
            {
              name: 'clear-cookies',
              label: this.$t('navigation.clear-cookies')
            }
          ]
        }
      ]
    };
  },
  computed: {
    useMode() { return useDataStore().getMode },
    scheduleFilter() { return useScheduleStore().scheduleOptions.scheduleFilter; },
    affinities() { return useDataStore().affinities; },
    hasAffinity() {
      const callGroups = this.callGroups;
      return callGroups.some(x => x.affinity !== null);
    },
    callGroups() { return useDataStore().skillsCurrentCC; },
    currentCC() { return useDataStore().currentCC; },
    currentUser() { return useDataStore().currentUser; },
    currentTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    showCurrentTimezone() {
      /** @type {string} */
      const routeName = this.$route.name;
      return routeName !== 'employees' && routeName !== 'settings';
    },
    employeeContext() { return useScheduleStore().employeeContext; },
    language: {
      get() {
        return useDataStore().language;
      },
      /**
      * @param {string} lang
      */
      set(lang) {
        useDataStore().language = lang;
      }
    },
    forecastIsAvailable() { return useForecastStore().forecastOptions.forecastIsLoaded; },
    refreshedDueToError() { return useDataStore().refreshedDueToError; },
    userGuide() { return useDataStore().userGuide; },
    showAffintyDropDown: function () {
      return (this.isPage('schedule') || (this.isPage('employees') && this.employeeContext.activeTab == 1)) && this.hasAffinity && this.affinities
    },
    showEmpFilter() {
      return this.isPage('schedule') || this.isPage('employees')
    },
    isAgentLogin: function () {
      return this.currentUser != null && this.currentUser.isAgent;
    },
    isCurrentCCSelected: function () {
      return this.currentCC != null;
    },

    isNormalLogin: function () {
      return this.currentUser != null && !this.currentUser.isAgent && !this.currentUser.isSuperAdmin;
    },
    isSuperAdmin: function () {
      return this.currentUser != null && this.currentUser.isSuperAdmin;
    },
    showForecast() {
      return this.forecastIsAvailable && this.currentUser !== null && this.currentUser.hasAccessForecasting && this.useMode == 0;
    },
    showReport() {
      return this.forecastIsAvailable && this.currentUser !== null && this.currentUser.hasAccessReports && this.isCurrentCCSelected;
    },
    showSchedule() {
      return this.forecastIsAvailable && this.currentUser !== null && this.currentUser.hasAccessScheduling && this.isCurrentCCSelected;
    },
    supervisorInfo() {
      if (this.isPage('schedule')) {
        return this.currentUser !== null && (this.currentUser.isSupervisor)
      }
    },
    showReadOnlyInfo() {
      if (this.isPage('schedule')) {
        return this.currentUser !== null && (!this.currentUser.canEditScheduling && this.isCurrentCCSelected)
      } else if (this.isPage('settings')) {
        return this.currentUser !== null && (!this.currentUser.canEditSettings)
      } else if (this.isPage('forecast')) {
        return this.currentUser !== null && (!this.currentUser.canEditForecasting)
      }
      return false;
    },
    showSettings() {
      return this.forecastIsAvailable && this.currentUser !== null && this.currentUser.hasAccessSettings;
    },
    fileImportInfo() { return useFileImportStore().fileImportInfo; },
    scheduleNeedsSave() { return useScheduleStore().scheduleNeedsToSave; },
    showExpireWarning() {
      return this.currentUser != null && this.currentUser.endTime !== null;
    },
    relevantInvoiceStatus() { return useDataStore().relevantInvoiceStatus },

    expireMessage() {
      return this.$t('navigation.program-will-stop-working') + `${this.currentUser.endTime}.`;
    }
  },
  methods: {
    checkInvoiceStatus() {
      useDataStore().loadRelevantInvoiceStatus()
    },
    layOutChange() {
      setTimeout(() => {
        EventBus.emit('scheduleLayoutChange');
      }, 500);
    },
    okSelectAvatar() {
      this.currentUser.initials = this.$refs.avatarSelector.initials
      this.currentUser.colorId = this.$refs.avatarSelector.colorId
      saveUsers([this.currentUser])
      this.activeSelectAvatar = false
    },
    filterChange() {
      EventBus.emit("simulationChange");
      EventBus.emit("schedulefilterchange")
    },
    affinitySelection() {
      const sel = [{ id: null, name: 'All departments' }];
      const callGroups = this.callGroups;
      const affinities = this.affinities;

      /** @type {Set<number>} */
      const usedAffinities = new Set();
      for (const cg of callGroups) {
        if (cg.affinity !== null) {
          usedAffinities.add(cg.affinity);
        }
      }
      for (const a of affinities) {
        if (usedAffinities.has(a.id)) {
          sel.push(a);
        }
      }
      return sel;
    },
    getAffinityText() {
      const id = this.scheduleFilter.affinity;
      if (id === null) {
        return "All departments";
      }

      /** @type {import("@/model/dn-affinity.js").Affinity[]} */
      const affinities = this.affinities;
      const affinity = affinities.find(x => x.id === id);
      if (affinity)
        return affinity.name;
      return id;
    },
    /**
    * @param {string} lang
    */
    setLanguage(lang) {
      dnLocalStorage.setLanguage(lang);
      loadLanguageAsync(lang);
      EventBus.emit('calculateReport');
    },
    async logOut() {
      const dataStore = useDataStore();
      this.userGuide.display = false
      dn_schedulehelper.saveLocalScheduleSettings()
      try {
        if (this.scheduleNeedsSave) {
          this.$confirm.require({
            message: `${this.$t('global.unsaved-schedule')}`,
            rejectLabel: 'Cancel',
            acceptLabel: 'Confirm',
            accept: () => { 
              dataStore.logOut();
              this.$router.push({ name: "login" });
             }
          });
        } else {
          dataStore.logOut();
          this.$router.push({ name: "login" });
        }
      } catch (e) {
        console.log(e);
      }
    },
    isPage: function (page) {
      return this.$route.name == page;
    },
    clearLocalStorage: function () {
      dnLocalStorage.clearLocalStorage()
    },
    goToView(name) {
      if (this.$route.name !== name) {
        /** @type {import("@/model/dn-employee-context.js").EmployeeContext} */
        const employeeContext = this.employeeContext;
        if (employeeContext.hasModifiedData) {
          this.$confirm.require({
            message: `${this.$t('global.discard-changes')}`,
            rejectLabel: `${this.$t('global.no')}`,
            acceptLabel: `${this.$t('global.yes')}`,
            accept: () => { 
              employeeContext.revertModifiedData();
              this.$router.push({ name: name });
             }
          });
        } else {
          this.$router.push({ name: name });
        }
      }
    },
  },
  watch: {
    fileImportInfo: {
      handler: function () {
        const fileImportInfo = this.fileImportInfo;
        if (fileImportInfo.error) {
          this.$toast.add({
            severity: 'error',
            detail: "File " + this.fileImportInfo.fileName + " has error(s)",
            life: 2000
          });
        }
        else if (fileImportInfo.nofImported > 0) {
          this.$toast.add({
            severity: 'secondary',
            detail: fileImportInfo.nofImported + " files imported",
            life: 2000
          });
        }
        const forecastStore = useForecastStore();
        forecastStore.reloadForecastAndHistoricData();
      },
      deep: true
    },
  },
  created() { },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.module.scss";
@import "@/assets/css/common.module.scss";

.dropdown-botton {
  border-radius: 4px;
  height: 22px;
  width: 80px;
  margin-left: 5px;
  margin-top: 5px
}

.supervior-info {
  color: $primary;
  margin-top: 19px;
  margin-right: 10px;
  min-width: 120px;
}

.filter-info {
  color: $primary;
  font-size: 12px;
  margin-right: 10px;
}

.invoice-info {
  margin-right: 10px;
  min-width: 120px;
}

.invoice-color-info {
  color: $success
}

.invoice-color-due {
  color: $warning
}

.invoice-color-overdue {
  font-weight: 900;
  color: $danger;
}

.affinity-info {
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#brand {
  margin-right: 20px;
  margin-top: 5px;
  padding: 0px;

}

.loggo {
  margin-left: -10px;
  margin-right: -10px;
  padding: 0px;
  width: 180px;
  height: 30px;
}

.navbar-wrapper {
  border-bottom: 1.5px solid $dark;
  margin-bottom: 2rem;

  .container {
    margin: 0;
    padding: 0 1.5rem;
    //margin-right: 160px;
    max-width: 100% !important;
  }

  .navbar {
    align-items: bottom;

    .comp-icon {
      margin-right: 2px;
    }

    .tag {
      margin-left: 0.5rem;
    }
  }
}

.mobile-navbar {
  display: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  margin-top: 0.75rem;

  .menu-item {
    color: $darker;
    padding: 0.5rem .75rem;

    &:hover {
      color: $primary;
    }
  }

  .menu-item.active {
    color: $primary;
  }

  .brand {
    flex-shrink: 0;
  }

  .right-menu,
  .navbar-brand {
    display: flex;
    align-items: center;
    flex-shrink: inherit;
  }

  .call-center {
    margin-left: 2rem;
  }
}

:deep(.p-menubar) {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .p-menuitem-link {
    color: $dark;
    padding: 0;

    &:hover {
      color: $primary;
      background-color: $selected;
    }
  }

  .p-menuitem {
    .pi-angle-down {
      color: $primary;
    }
  }

  .p-menuitem.p-focus {
    .p-menuitem-content {
      background: none;
    }
  }

  .p-menuitem.p-menuitem-active .p-highlight {
    .p-submenu-list {
      margin-left: 10px;
    }
  }

  .p-menuitem-content {
    &:hover {
      background: none;
      color: $primary;
    }

    padding: 0.3rem 1rem;
  }

  .p-submenu-list {
    min-width: fit-content;
    left: -50%;
    text-wrap: nowrap;

    .p-menuitem-content {
      cursor: pointer;
    }
  }
}

.header-msg {
  margin: 0;

  :deep(.p-message-wrapper) {
    justify-content: space-between;
    padding: 0.4rem;
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  :deep(.navbar) {
    display: none;
  }

  .mobile-navbar {
    display: flex;
  }

  .navbar-wrapper {
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

@media screen and (max-width: $desktopBreakPoint) {
  .navbar-brand {
    .search-btn {
      margin-left: auto;
    }

    .navbar-item {
      padding-left: 0;
    }
  }

  :deep(.p-menubar) {
    flex-grow: 1;
    justify-content: flex-end;

    .call-center {
      margin-left: 0;
    }
  }

  :deep(.navbar-burger) {
    margin-left: 0;
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  :deep(.navbar-brand) {
    //width: calc(100% - 8rem);
  }

  :deep(.navbar-end) {
    width: 300px;
    align-items: right;
  }

  :deep(.navbar-menu) {
    flex-grow: 0;
  }

  :deep(.navbar-dropdown) {
    border-top: 1px solid #dbdbdb;
    left: auto;
    right: 0;
  }

  .dropdown-subitem {
    color: $dark;
    font-size: 15px;
  }

  :deep(li) {
    list-style-type: none;
  }
}
</style>
