
import { addDays, getDayDiff } from "@/js/dn-helper.js";
import { getDateTimezoneInfo } from "@/js/dn-timezone.js";

export class DateInterval {
  /**
   * @param {Date} date
   * @param {number} numberOfDays
   * @param {string} [timezone]
   */
  constructor(date, numberOfDays, timezone = undefined) {
    /** @readonly @type {number} */
    this.numberOfDays = numberOfDays;

    /** @readonly @private @type {{dt:Date;dtLocal:Date;shortDate:string}[]} */
    this.days = [];
    for (let i = 0; i < numberOfDays + 2; i++) {
      const dtLocal = addDays(date, i - 1);
      this.days.push(getDateTimezoneInfo(dtLocal, timezone));
    }

    //daylight saving correction in hours, if transition is within planning range
    /** @readonly @type {Map<string, number>} */
    this.dayLightSavingCorrs = new Map();
    const stTime = this.st.getTime();
    for (let d = -1; d < this.numberOfDays + 1; d += 1) {
      const dayInfo = this.byIndex(d);
      const dt = dayInfo.dt;
      this.dayLightSavingCorrs.set(dayInfo.shortDate, dt.getTime() / (60 * 60 * 1000) - (d * 24) - Math.round(stTime / (60 * 60 * 1000)))
    }
  }

  get st() {
    return this.byIndex(0).dt;
  }

  get fi() {
    return this.byIndex(this.numberOfDays).dt;
  }

  /**
   * @param {number} index
   */
  byIndex(index) {
    return this.days[index + 1];
  }
}

/**
 * @param {Date[]} dateArray
 * @param {{timezoneUI:string}} user
 */
export function fromDateArray(dateArray, user) {
  if (!user || dateArray.length === 0) { return undefined; }
  const numberOfDays = 1 + getDayDiff(dateArray[1], dateArray[0]);
  return new DateInterval(dateArray[0], numberOfDays, user.timezoneUI);
}