export class ModelObject {
  /**
   * @param {number} id
   */
  constructor(id) {
    
    /** @protected @type {number} */
    this._id = id;

    /** @private @type {boolean} */
    this._hasChanges = this._id < 0;

    /** @private @type {boolean} */
    this._isToDelete = false;
  }

  /** @return {number} */
  get id() {
    return this._id;
  }
  set id(value) {
    this._id = value;
  }

  /** @return {boolean} */
  get isToDelete() {
    return this._isToDelete;
  }

  /** @return {boolean} */
  get hasChanges() {
    return this._hasChanges;
  }

  isInDB() {
    return this._id >= 0;
  }

  /**
   * @private
   * @param {string} prop
   * @param {any} value
   * @param {(arg0: any) => any} [eqProjection]
   */
  setValueImpl(prop, value, eqProjection) {
    const originalValue = this[prop];
    let projOriginalValue = originalValue;
    let projValue = value;
    if (eqProjection) {
      projOriginalValue = eqProjection(originalValue);
      projValue = eqProjection(projValue);
    }

    const diff = projOriginalValue !== projValue;
    if (diff) {
      this[prop] = value;
      if (this.originalMap === undefined) {
        /** @type {Map<string, {value:any;projValue:any}>} */
        this.originalMap = new Map();
        this.originalMap.set(prop, { value: originalValue, projValue: projOriginalValue });
      } else {
        const original = this.originalMap.get(prop);
        if (original === undefined) {
          this.originalMap.set(prop, { value: originalValue, projValue: projOriginalValue });
        } else if (original.projValue === projValue) {
          this.originalMap.delete(prop);
        }
      }
      this.setHasChanges();
    }
    return diff;
  }

  /**
   * @protected
   * @param {string} prop
   * @param {number|string|boolean} value
   */
  setValue(prop, value) {
    return this.setValueImpl(prop, value);
  }

  /**
   * @protected
   * @param {string} prop
   * @param {Date} value
   */
  setDateValue(prop, value) {
    return this.setValueImpl(prop, value, dateProj);
  }

  /**
   * @protected
   * @param {string} prop
   * @param {number[]} value
   */
  setNumberArrayValue(prop, value) {
    return this.setValueImpl(prop, value, numberArrayProj);
  }

  /**
   * @private
   * @param {string} prop
   * @param {any} value
   * @param {(arg0: any) => any} [eqProjection]
   */
  setOriginalValueImpl(prop, value, eqProjection) {
    let x = this[prop];
    let projValue = value;
    if (eqProjection) {
      x = eqProjection(x);
      projValue = eqProjection(value);
    }

    const diff = x !== projValue;
    if (diff) {
      if (this.originalMap === undefined || !this.originalMap.has(prop)) {
        this[prop] = value;
      } else {
        this.originalMap.set(prop, { value: value, projValue: projValue });
      }

      this.setHasChanges();
    } else {
      if (this.originalMap !== undefined && this.originalMap.has(prop)) {
        this.originalMap.delete(prop);
        this.setHasChanges();
      }
    }
    return diff;
  }

  /**
   * @protected
   * @param {string} prop
   * @param {number|string|boolean} value
   */
  setOriginalValue(prop, value) {
    return this.setOriginalValueImpl(prop, value);
  }

  /**
   * @protected
   * @param {string} prop
   * @param {Date} value
   */
  setOriginalDateValue(prop, value) {
    return this.setOriginalValueImpl(prop, value, dateProj);
  }

  /**
   * @param {{itemHasChanged:(hasChanges:boolean) => void; itemChanged?: (model:ModelObject) => void}} container
   */
  setContainer(container) {
    /** @private @type {{itemHasChanged:(hasChanges:boolean) => void; itemChanged?: (model:ModelObject) => void}} */
    this._container = container;
  }

  hasContainer(){ 
    return this._container!==undefined
  }

  /** @protected */
  setHasChanges() {
    /** @type {boolean} */
    let hasChanges;
    if (this.id < 0) {
      hasChanges = !this.isToDelete;
    } else {
      hasChanges = this.isToDelete || (this.originalMap !== undefined && this.originalMap.size > 0);
    }
    if (this._container !== undefined && this._container.itemChanged !== undefined) {
      this._container.itemChanged(this);
    }
    if (this._hasChanges !== hasChanges) {
      this._hasChanges = hasChanges;
      if (this._container !== undefined) {
        this._container.itemHasChanged(hasChanges);
      }
    }
  }

  /**
   * @protected
   * @param {string[]} fields
   */
  hasChangesPart(fields) {
    if (this.originalMap !== undefined) {
      for (const field of fields) {
        if (this.originalMap.has(field)) { return true; }
      }
    }

    return false;
  }

  confirmChanges() {
    this.originalMap = undefined;
    this.setHasChanges();
  }

  revert() {
    if (this.originalMap !== undefined) {
      for (const [prop, original] of this.originalMap) {
        this[prop] = original.value;
      }
      this.confirmChanges();
    }
  }

  resurrect() {
    this._isToDelete = false;
    this.setHasChanges();
  }

  toDelete() {
    if (this.id < 0) {
      this.originalMap = undefined;
    }
    this._isToDelete = true;
    this.setHasChanges();
  }
  undoToDelete(){
    this._isToDelete = false;
    this.setHasChanges();
  }
}

/**
 * @param {Date|null} dt
 */
function dateProj(dt) {
  return dt !== null ? dt.getTime() : null;
}

/**
 * @param {number[]} list
 */
function numberArrayProj(list) {
  list.sort();
  return list.join('_');
}