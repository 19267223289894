export class RequestItem {
  /**
  * @param {number} id
  * @param {boolean|null} approved
  * @param {Date} created
  */
  constructor(id, approved, created) {
    /** @type {number} */
    this.id = id;
    /** @type {Date} */
    this.created = created;
    /** @type {boolean|null} */
    this.approved = approved;
    /** @private @type {boolean} */
    this._pending = false;
  }

  get days() {
    return 1;
  }

  get employeeCount() {
    return 1;
  }

  get employeesToDisplay() {
    return [];
  }

  get isRejected() {
    return this.approved === false;
  }

  get isApproved() {
    return this.approved === true;
  }

  get isOpen() {
    return !(this.isApproved || this.isRejected);
  }

  get isPending() {
    return this._pending;
  }

  get isPost() {
    return false;
  }

  get isTrade() {
    return false;
  }

  get iconName() {
    return '';
  }

  get key() {
    return this.id;
  }

  /**
   * @param {string} timezone
   * @returns {string[]}
   */
  getSelectionKeys(timezone) {
    return [];
  }

  /**
   * @param {{ id: number; }} employee
   */
  canDelete(employee = null) { // eslint-disable-line no-unused-vars
    return false;
  }

  canEdit() {
    return false;
  }

  canEvaluate() {
    return false;
  }

  canReopen() {
    return false;
  }

  /**
   * @param {{ id: number; }} employee
   */
  canReply(employee = null) { // eslint-disable-line no-unused-vars
    return false;
  }

  /**
   * @param {boolean} approved
   * @param {{ id: number; }| undefined} employee
   */
  canReplyWithAnswer(approved, employee) {
    if (!employee)
      return true;

    return this.canReplyWithAnswerEmp(approved, employee);
  }

  /**
   * @protected
   * @param {boolean} approved
   * @param {{ id: number; }} employee
   */
  canReplyWithAnswerEmp(approved, employee) { // eslint-disable-line no-unused-vars
    return false;
  }

  async deleteRequest() {
  }

  /**
   * @param {number} index
   */
  getEmployeeIdByIndex(index) { // eslint-disable-line no-unused-vars
    return 0;
  }

  getMsgToDisplay() {
    return '';
  }

  getReplyMsg() {
    return '';
  }

  /**
 * @param {number} index
 */
  getNumberOfStarsByIndex(index) { // eslint-disable-line no-unused-vars
    return 0;
  }

  /**
   * @param {{ id: number; }} employee
   * @returns {{id:number;msg:string;numberOfStars?:number}}
   */
  getReplyObj(employee) { // eslint-disable-line no-unused-vars
    return undefined;
  }

  /**
   * @param {any} srEmp
   * @param {string} language
  * @param {string} timeZone
   * @returns {string[]}
   */
  getTasksInfo(srEmp, language, timeZone) { // eslint-disable-line no-unused-vars
    return [];
  }

  /**
   * @param {string} key
   * @param {string} timezone
   */
  hasSelectionKey(key, timezone) { // eslint-disable-line no-unused-vars
    return false;
  }

  async reopen() {
  }

  setAsNonPending() {
    this._pending = false;
  }

  /** @protected */
  setPendingState() {
    this._pending = this.approved !== null;
  }

  /** @returns {Date[]} */
  affectedScheduleInterval() {
    return [];
  }

  /**
   * @param {import("@/model/dn-task.js").ScheduleTasks} scheduleTasks
   * @param {import("@/model/dn-tasktype.js").TaskTypes} taskTypes
   * @param {import("@/model/dn-employee.js").Employee} employee
   * @param {boolean|null} approve
   * @param {{id:number;msg:string;numberOfStars?:number}} reply
   * @returns {Promise<{ok:boolean; affectedDates?:Set<string>; toastMsg?:string;}>}
   */
  async toggleApproval(scheduleTasks, taskTypes, employee, approve, reply) { // eslint-disable-line no-unused-vars
    return { ok: false };
  }
}