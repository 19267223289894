import { getLoginInfo, setLoginInfo } from './dn-localStorage.js';
import { LoginInfo } from '@/model/dn-login.js';
import { EventBus } from "./dn-event-bus.js";
const apiPath = 'https://api-dot-secure-ripple-312910.nw.r.appspot.com/';

export function getApiPath() {
  return apiPath;
}

/**
 * @param {{ email: string; password: string; }} userCredentials
 * @param {{ loginId: number; password: string; }} [credentials2]
 * @returns {Promise<{msg?:string;cause?:number;loginId?:number; refreshToken?:string;accessToken?:string;user?:{id:number;email:string;name:string;authMethod:number|null;reqNotify:number|null}}>}
 */
export async function login(userCredentials, credentials2 = undefined) {
  const url = apiPath + 'authentication/user';
  const body = {
    strategy: 'local',
    email: userCredentials.email,
    password: userCredentials.password
  };

  if (credentials2) {
    body.loginId = credentials2.loginId;
    body.password2 = credentials2.password;
  }

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  try {
    const response = await fetch(url, options);
    const json = await response.json();
    if (response.ok) return json;
    
    let cause = 0;
    let msg;
    if (response.status === 401) {
      msg = 'Wrong email or password.';
      if (json.data) {
        cause = json.data.cause;
        console.log(cause)
      }
    } else { 
      msg = response.statusText; 
    }
    
    return { msg, cause};

  } catch (error) {
    console.log(error)
    return { msg: error.message};
  }
}

let _lastUpdateAccessToken = 0;

/**
 * @param {import("@/model/dn-login.js").LoginInfo} loginInfo
 */
export async function updateAccessToken(loginInfo) {
  // avoid duplicated calls.
  if (Date.now() - _lastUpdateAccessToken < 1000 * 30)  {
    let counter = 0;
    do {
      await new Promise((resolve) => {
        setTimeout(() => resolve(), 1000)
      });
      if (!loginInfo.needRefresh()) {
    return;
      }

      counter++;
    } while (counter < 10);

    return;
  }

  _lastUpdateAccessToken = Date.now();
  const url = apiPath + 'authentication/user';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'strategy': 'local',
      'action': 'refresh',
      'refresh_token': loginInfo.refreshToken
    })
  };
  return await fetch(url, options).then(returnJson)
    .then(function (response) {
      loginInfo.setAuthRefreshResult(response);
      setLoginInfo(loginInfo);
    })
}

export async function passwordReset(accCredentials) {
  const url = apiPath + 'user/change-password';
  const options = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'accToReset': {
        'userName': accCredentials.username,
        'password': accCredentials.password,
        'newPassword': accCredentials.newPassword
      }
    })
  };

  return await fetch(url, options)
    .then(function (response) {
      let result;
      console.log(response)
      if (response.status === 401) {
        result = {
          msg: 'Wrong username or password!',
          success: false
        }
        return result;
      }
      else if (response.status === 200) {
        result = {
          msg: 'Success',
          success: true
        }
        return result;
      }
    }).catch(function (error) {
      return error.statusText
    });
}

/**
 * @param {string} key
 * @param {(isAgent) => void} afterExternalLogin
 */
export async function externalLogin(key, afterExternalLogin) {
  const options = { method: 'PATCH' };
  const url = apiPath + `authentication/external/${key}`;
  try {
    /** @type {{accessToken:string, user: {id:number;email:string;isAgent:boolean;name:string, schema:string}}} */
    const result = await fetch(url, options).then(checkIfOk).then(returnJson);
    const loginInfo = new LoginInfo({ accessToken: result.accessToken, customerSchema: result.user.schema, isAgent: result.user.isAgent });
    setLoginInfo(loginInfo);
    afterExternalLogin(loginInfo.isAgent);
  } catch (error) {
    afterExternalLogin();
  }
}

export function getSchemas() {
  return fetchAndCheckJson('schema-selection/', 'GET')
}

export function getLongestCalls(date, maxToReturn, longestCallKind) {
  let path =
    'callDetails/longestCalls?dt=' +
    date +
    '&maxToReturn=' +
    maxToReturn +
    '&longestCallKind=' +
    longestCallKind;
  return fetchAndCheckJson(path, 'GET');
}

export async function getCustomerData(schema) {
  let path = 'customer-data';
  if (schema)
    path += '?schema=' + schema;
  return await fetchAndCheckJson(path, 'GET');
}

export async function saveForecastAdjustment(adjustmentsToSave){
  await fetchAndCheckJson(`forecastadjustment`,'POST', adjustmentsToSave);
}

/**
 * @param {number} contactCenterId
 * @param {FormData} formData
 */
export async function importEmployeeData(contactCenterId, formData) {
  let loginInfo = getLoginInfo();
  if (loginInfo === null) {
    throw 'No login.';
  }

  if (loginInfo.needRefresh()) {
    await updateAccessToken(loginInfo);
  }
  const accessToken = loginInfo.accessToken;
  const response = await fetch(`${apiPath}import-employee-data?ccId=${contactCenterId}`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + accessToken,
      'dn-customer-schema': loginInfo.customerSchema
    },
    body: formData
  });

  if (response.status !== 201) {
    let json = await response.json();
    throw json.message;
  }

  return await response.text();
}

/**
 * @param {string} resource
 * @param {string} method
 * @param {any} data
 */
async function fetchAndCheck(resource, method, data) {
  EventBus.emit('onError', null);
  const loginInfo = getLoginInfo();
  if (loginInfo === null) {
    return Promise.reject(new Error('No login.'));
  }

  if (loginInfo.needRefresh()) {
    await updateAccessToken(loginInfo);
  }

  const options = getOptions(method, loginInfo, data);
  var url = apiPath + resource;
  return fetch(url, options)
    .then(checkIfOk)
    .catch(function (error) {
      EventBus.emit('onError', error.message);
      return Promise.reject(error);
    });
}

export async function fetchAndCheckJson(resource, method, data = undefined) {
  return fetchAndCheck(resource, method, data).then(returnJson);
}

function checkIfOk(response) {
  if (response.ok) return response;
  let msg = '';
  if (response.status === 401) EventBus.emit('unauthorized');
  else msg = response.statusText;
  throw new Error(msg);
}

function returnJson(response) {
  if (response.status === 204) return null;
  return response.json();
}

/**
 * @param {string} method
 * @param {import("@/model/dn-login.js").LoginInfo} loginInfo
 * @param {any} data
 * @return {RequestInit}
 */
function getOptions(method, loginInfo, data) {
  if (data === undefined || data === null) {
    return {
      method: method,
      headers: {
        Authorization: 'Bearer ' + loginInfo.accessToken,
        'dn-customer-schema': loginInfo.customerSchema
      }
    };
  }

  return {
    method: method,
    headers: {
      Authorization: 'Bearer ' + loginInfo.accessToken,
      'Content-Type': 'application/json',
      'dn-customer-schema': loginInfo.customerSchema
    },

    body: JSON.stringify(data)
  };
}