import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays } from "@/js/dn-helper.js";
import { Task } from '@/model/dn-task.js';

/**
 * @param {{st:Date; numberOfDays:number}[]} dateIntervals
 * @param {number} id
 * @param {boolean} idIsEmp
 * @return {Promise<Task[]>}
 */
export async function getScheduleTasks(dateIntervals, id, idIsEmp = false) {
  const idParam = idIsEmp ? 'empId' : 'ccid';
  const st = dateIntervals.map(x => x.st.toISOString()).join(';');
  const fi = dateIntervals.map(x => (new Date(addDays(x.st, x.numberOfDays))).toISOString()).join(';');
  /** @type {import("@/model/dn-task.js").TaskDto[]} */
  const result = await fetchAndCheckJson(`scheduletasks?st=${st}&fi=${fi}&${idParam}=${id}`, 'GET');
  return result.map(x => new Task(x));
}

/**
 * @param {{st:Date; numberOfDays:number}[]} dateIntervals
 * @param {number} id
 * @param {boolean} idIsEmp
 * @param {{id:number;histId:number}} last
 * @return {Promise<{tasks:import("@/model/dn-task.js").TaskDto[];last:{id:number; histId:number};deleted?:number[];updated?:import("@/model/dn-task.js").TaskDto[]}>}
 */
async function getScheduleTasksSync(dateIntervals, id, idIsEmp = false, last) {
  const idParam = idIsEmp ? 'empId' : 'ccid';
  const st = dateIntervals.map(x => x.st.toISOString()).join(';');
  const fi = dateIntervals.map(x => (new Date(addDays(x.st, x.numberOfDays))).toISOString()).join(';');

  /** @type {{tasks:import("@/model/dn-task.js").TaskDto[];last:{id:number; histId:number};deleted?:number[];updated?:import("@/model/dn-task.js").TaskDto[]}} */
  const result = await fetchAndCheckJson(`scheduletasks?st=${st}&fi=${fi}&${idParam}=${id}&minId=${last.id}&minHistId=${last.histId}`, 'GET');
  return result;
}

/**
 * @param {{st:Date; numberOfDays:number}[]} dateIntervals
 * @param {number} id
 * @param {boolean} idIsEmp
 * @return {Promise<{tasks:Task[];last:{id:number; histId:number};deleted?:number[];updated?:import("@/model/dn-task.js").TaskDto[]}>}
 */
export async function getScheduleTasksUpdated(dateIntervals, id, idIsEmp = false) {
  const result = await getScheduleTasksSync(dateIntervals, id, idIsEmp, { id: 0, histId: 0 });
  return { tasks: result.tasks.map(x => new Task(x)), last: result.last };
}

/**
 * @param {import("@/model/dn-task.js").TaskDto} dto
 */
function getUniqueTaskDtoKey(dto) {
  const st = new Date(dto.st);
  return dto.empid + '_' + dto.tasktypeId + '_' + st.getTime();
}

/**
 * @param {Task} t
 */
function getUniqueTaskKey(t) {
  return t.empid + '_' + t.tasktypeId + '_' + t.st.getTime();
}

/**
 * @param {{st:Date; numberOfDays:number}[]} dateIntervals
 * @param {number} id
 * @param {boolean} idIsEmp
 * @param {{id:number;histId:number}} last
 * @param {import("@/model/dn-task.js").ScheduleTasks} scheduleTasks
 */
export async function updateScheduleTasks(dateIntervals, id, idIsEmp, last, scheduleTasks) {
  scheduleTasks.removeUnsavedDeletedTasks();
  const tasks = scheduleTasks.list;
  const result = await getScheduleTasksSync(dateIntervals, id, idIsEmp, last);
  let wasChanged = false
  if (result.deleted && result.deleted.length > 0) {
    const toDelete = new Set(result.deleted);
    for (let i = tasks.length - 1; i >= 0; i--) {
      const t = tasks[i];
      if (t.id >0 && toDelete.has(t.id)) {
        if (t.hasChanges) {
          t.id = -1;
          t.confirmChanges();
        } else {
          t.toDelete();
          tasks.splice(i, 1);
        }
      }
    }
    wasChanged = true;
  }

  if ((result.updated && result.updated.length > 0) || result.tasks.length > 0) {
    wasChanged = true;
    const currentById = new Map(tasks.filter(x => x.id > 0).map(x => [x.id, x]));
    const unsavedByUniqueKey = new Map(tasks.filter(x => x.id <= 0).map(x => [getUniqueTaskKey(x), x]));
    if (result.updated) {
      for (const dto of result.updated) {
        updateOrInsert(scheduleTasks, dto, currentById, unsavedByUniqueKey);
      }
    }
    for (const dto of result.tasks) {
      updateOrInsert(scheduleTasks, dto, currentById, unsavedByUniqueKey);
    }
  }

  last.id = result.last.id;
  last.histId = result.last.histId;
  return wasChanged;

  /**
   * @param {import("@/model/dn-task.js").ScheduleTasks} scheduleTasks
   * @param {import("@/model/dn-task.js").TaskDto} dto
   * @param {Map<number, Task>} currentById
   * @param {Map<string, Task>} unsavedByUniqueKey
   */
  function updateOrInsert(scheduleTasks, dto, currentById, unsavedByUniqueKey) {
    let t = currentById.get(dto.id);
    if (t !== undefined) {
      t.update(dto);
    } else {
      const key = getUniqueTaskDtoKey(dto);
      t = unsavedByUniqueKey.get(key);
      if (t !== undefined) {
        t.update(dto);
      } else {
        t = new Task(dto);
        scheduleTasks.add(t);
        currentById.set(t.id, t);
      }
    }
  }
}
