import { EventBus } from "@/js/dn-event-bus.js";
import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays, getSelectionKey, getStartOfDay, sortDateIntervals } from '@/js/dn-helper.js';
import { getTask, getTimeNow } from '@/js/dn-schedule-helper.js';
import { getDateOfInstant } from '@/js/dn-timezone.js';
import { getShortDate, getDurationInMinutes } from '@/js/dn-uihelper.js';
import { getShiftBounds } from "@/model/dn-employee-schedule.js";
import { RequestItem } from '@/model/dn-request-item.js';
import { Task } from '@/model/dn-task';
import { TASK_KIND } from '@/model/dn-tasktype.js';
import { useDataStore } from "@/stores/dataStore.js";
import { useScheduleStore } from "@/stores/scheduleStore.js";

export class ScheduleRequest extends RequestItem {

  /**
   * @param {ScheduleRequestEmployee[]} employees
   * @param {number} callCenterId
   * @param {number} kind
   * @param {number|null} days
   * @param {string} msg
   * @param {boolean|null} approved
   * @param {Date|undefined} created
   * @param {Date|undefined} updated
   */
  constructor(employees, callCenterId, kind, days = null, msg = '', approved = null, id = undefined, created = undefined, updated = undefined) {
    super(id, approved, created);
    /** @type {Date | undefined} */
    this.updated = updated;
    /** @readonly @type {ScheduleRequestEmployee[]} */
    this.employees = employees;
    /** @readonly @type {number} */
    this.callCenterId = callCenterId;
    /** @readonly @type {number} */
    this.kind = kind;
    /** @private @readonly @type {number} */
    this._days = days ? days : 1;
    /** @type {string} */
    this.msg = msg;
    /** @private @type {boolean} */
    this._evaluate = false;
    if (this.kind === REQUEST_KIND.post) {
      /** @type {Date} */
      let dt = undefined;
      for (const srEmp of this.employees) {
        for (const t of srEmp.tasks) {
          if (t.scheduleTask !== null && (dt === undefined || t.scheduleTask.st < dt)) {
            dt = t.scheduleTask.st;
          }
        }
      }
      for (const srEmp of this.employees) {
        srEmp.starts.push(dt);
      }
    } else {
      for (const srEmp of this.employees) {
        const dtFrom = getStartFrom(srEmp.employeeId);
        if (dtFrom !== undefined)
          srEmp.starts.push(dtFrom);
        const dtTo = getStartTo(srEmp);
        if (dtTo !== undefined)
          srEmp.starts.push(dtTo);
      }
    }

    function getStartFrom(employeeId) {
      let dt = undefined;
      for (const srEmp of employees) {
        if (srEmp.employeeId !== employeeId) {
          for (const t of srEmp.tasks) {
            if (t.scheduleTask !== null && t.scheduleTask.empId === employeeId && (dt === undefined || t.scheduleTask.st < dt)) {
              dt = t.scheduleTask.st;
            }
          }
        }
      }

      return dt;
    }

    function getStartTo(srEmp) {
      let dt = undefined;
      for (const t of srEmp.tasks) {
        if (dt === undefined || t.st < dt) {
          dt = t.st;
        }
      }

      return dt;
    }
  }

  get days() {
    return this._days
  }

  get employeeCount() {
    return this.employees.length;
  }

  get iconName() {
    switch (this.kind) {
      case REQUEST_KIND.post:
        return 'swap';
      case REQUEST_KIND.sick:
        return 'sick';
      case REQUEST_KIND.free:
        return 'free';
      case REQUEST_KIND.vacation:
        return 'vacation';
      case REQUEST_KIND.trade:
        return 'swap';
      case REQUEST_KIND.work:
        {
          if (this.isApproved) {
            return 'work_glad';
          } else if (this.isRejected) {
            return 'work_sad'
          }
          else {
            return 'work';
          }
        }
    }

    return 'work';
  }

  get isPost() {
    return this.kind === REQUEST_KIND.post;
  }

  get isRejected() {
    if (this.approved === null) {
      return this.employees.some(x => x.approved === false);
    }
    return this.approved == false
  }
  get isTrade() {
    if (this.employees.length <= 1)
      return false;
    let empIds = [];
    for (const srEmp of this.employees) {
      for (const srTask of srEmp.tasks) {
        if (srTask.scheduleTask !== null && !empIds.some(x => x === srTask.scheduleTask.empId)) {
          empIds.push(srTask.scheduleTask.empId);
        }
      }
    }

    return empIds.length > 1;
  }

  /** @returns {Date[]} */
  affectedScheduleInterval() {
    /** @type {Date} */
    let st;
    /** @type {Date} */
    let fi;
    for (const srEmp of this.employees) {
      for (const requestTask of srEmp.tasks) {
        if (requestTask.scheduleTask) {
          const t = requestTask.scheduleTask;
          if (!st || st < t.st) {
            st = t.st;
          }
          if (!fi || fi > t.fi) {
            fi = t.fi;
          }
        }
      }
    }
    if (st && fi) {
      return [getStartOfDay(st), getStartOfDay(fi)];
    }
    return [];
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} schedule
   */
  canBePicked(schedule) {
    if (this.approved !== null)
      return false;

    if (this.employees[0].employeeId === schedule.emp.id)
      return false;

    for (let index = 1; index < this.employees.length; index++) {
      if (this.employees[index].employeeId === null) {
        return this.possibleWithSchedule(schedule);
      }
    }

    return false;
  }
  get evaluate() {
    return this._evaluate
  }
  set evaluate(value) {
    this._evaluate = value
  }

  /**
   * @param {string} timezone
   * @returns {string[]}
   */
  getSelectionKeys(timezone) {
    const keys = [];
    const maxCount = this.approved === true ? 2 : this.employees.length;
    let count = 0;

    for (const srEmp of this.employees) {
      if (count >= maxCount) { break; }
      count += 1;
      if (srEmp.employeeId !== null) {
        for (const st of srEmp.starts) {
          const stLocal = getDateOfInstant(st, timezone);
          let d = 0;
          while (d < this.days) {
            const dt = d > 0 ? addDays(stLocal, d) : stLocal;
            const key = getSelectionKey(dt, srEmp.employeeId);
            if (!keys.includes(key)) { keys.push(key) }
            d++;
          }

        }
      }
    }
    return keys
  }

  canReopen() {
    return !this.isPending && !this.isOpen;
  }

  /**
   * @param {{ showSick: boolean; showVacation: boolean; showFree: boolean; showWork: boolean; showBasedOnSelection: boolean; showFuture: boolean; showHistoric: boolean; showPending: boolean; showApproved: boolean; showRejected: boolean;tagId: number }} filter
   * @param {Date} future
   * @param {number} selectedEmpId
   */
  isFilterOk(filter, future, selectedEmpId) {


    if (this.approved === null && !this.canReply()) {
      return false;
    }

    if (this.kind === REQUEST_KIND.sick) {
      if (!filter.showSick) { return false; }
    } else if (this.kind === REQUEST_KIND.vacation) {
      if (!filter.showVacation) { return false; }
    } else if (this.kind === REQUEST_KIND.free) {
      if (!filter.showFree) { return false; }
    } else if (this.kind === REQUEST_KIND.work || this.kind === REQUEST_KIND.trade || this.kind === REQUEST_KIND.post) {
      if (!filter.showWork) { return false; }
    }

    let okByDate = true
    let okBySelectedEmps = true
    if (filter.showBasedOnSelection) {
      okBySelectedEmps = false
      for (const srEmp of this.employees) {
        if (srEmp.employeeId === selectedEmpId) {
          okBySelectedEmps = true;
          break;
        }
      }
    }
    let okByTag= true
    if(filter.tagId>0){
      okByTag=false
      for (const srEmp of this.employees) {
        let tagList= [filter.tagId]
        let emp= useScheduleStore().employees.list.find(x => x.id==srEmp.employeeId)
        if (emp&&emp.hasAnyTag(tagList)) {
          okByTag=true
        }
      }
     };
    
    let dtRange = this.getDateRange()
    if (dtRange[0] > future && !filter.showFuture) { okByDate = false }
    if (dtRange[1] < getTimeNow() && !filter.showHistoric) { okByDate = false }

    let okByApproval = false;
    if (filter.showPending && this.isPending) { okByApproval = true }
    if (this.isApproved && filter.showApproved) { okByApproval = true }
    if (this.isRejected && filter.showRejected) { okByApproval = true }
    if (this.isOpen) { okByApproval = true }

    return  okByApproval && okBySelectedEmps && okByDate&&okByTag;
  }

  /**
   * @private
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} schedule
   */
  possibleWithSchedule(schedule) {
    const di = schedule.dateInterval;
    const dt = di.byIndex ? di.byIndex(0).dtLocal : di.st;
    const employeeId = schedule.emp.id;
    for (const srEmp of this.employees) {
      if (srEmp.employeeId === employeeId || srEmp.employeeId === null) {
        let ok = false;
        if (di.byIndex) {
          for (const start of srEmp.starts) {
            if (start >= di.byIndex(0).dt && start < di.byIndex(1).dt) {
              ok = true;
            }
          }
        } else {
          for (const start of srEmp.starts) {
            if (start.getFullYear() === dt.getFullYear() && start.getMonth() === dt.getMonth() && start.getDate() === dt.getDate()) {
              ok = true;
            }
          }
        }

        if (!ok)
          return false;

        for (const srTask of srEmp.tasks) {
          for (const t of schedule.tasks) {
            if (t.overlaps(srTask.st, srTask.fi))
              return false;
          }
        }

        return true;
      }
    }

    return false;
  }
  /**
   * @param {string} key
   * @param {string} timezone
   */
  hasSelectionKey(key, timezone) {
    const maxCount = this.approved === true ? 2 : this.employees.length;
    let count = 0;
    for (const srEmp of this.employees) {
      if (count >= maxCount) { break; }
      count += 1;
      if (srEmp.employeeId !== null) {
        for (const st of srEmp.starts) {
          const stLocal = getDateOfInstant(st, timezone);
          let d = 0;
          while (d < this.days) {
            const dt = d > 0 ? addDays(stLocal, d) : stLocal;
            if (getSelectionKey(dt, srEmp.employeeId) === key)
              return true;
            d++;
          }
        }
      }
    }
    return false;
  }

  getDateRange() {
    let returnRange = [new Date(1970, 1, 1, 0, 0, 0, 0), new Date(2270, 1, 1, 0, 0, 0, 0)]
    for (const srEmp of this.employees) {
      for (const dt of srEmp.starts) {
        if (dt > returnRange[0]) { returnRange[0] = dt }
        const dtEnd = this.days > 1 ? addDays(dt, this.days - 1) : dt;
        if (dtEnd < returnRange[1]) { returnRange[1] = dtEnd }
      }
    }
    return returnRange
  }

  /** @private */
  clearPick() {
    if (this.kind === REQUEST_KIND.post) {
      const replyEmp = this.employees[1];
      replyEmp.employeeId = null;
      replyEmp.msg = '';
      replyEmp.numberOfStars = 0;
    }
  }

  /**
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   * @param {Set<string>} affectedDates
   */
  approveToggle(scheduleTasks, affectedDates) {
    if (!this.isValid(scheduleTasks))
      return false;
    const revert = this.approved === true;
    if (revert) { this.clearPick(); }
    this.performChange(scheduleTasks, getTask, revert);
    this.addToAffectedDates(affectedDates);
    this.approved = revert ? null : true;
    this.evaluate = false;
    this.setPendingState();
    return true;
  }

  canEdit() {
    return this.isOpen && !this.isTrade;
  }

  /**
   * @param {number} index
   */
  getEmployeeIdByIndex(index) {
    return this.employees[index].employeeId;
  }

  /**
   * @param {number} index
   */
  getNumberOfStarsByIndex(index) {
    return this.employees[index].numberOfStars;
  }

  /**
   * @param {ScheduleRequestEmployee} picked
   */
  selectPickEmployee(picked) {
    const replyEmp = this.employees[1];
    replyEmp.employeeId = picked.employeeId;
    replyEmp.approved = true;
    replyEmp.msg = picked.msg;
    replyEmp.numberOfStars = picked.numberOfStars;
  }

  /**
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   * @param {Set<string>} affectedDates
   */
  rejectToggle(scheduleTasks, affectedDates) {
    const revert = this.approved === false;
    if (this.approved === true) {
      if (!this.isValid(scheduleTasks))
        return false;
      this.clearPick();
      this.performChange(scheduleTasks, getTask, true);
      this.addToAffectedDates(affectedDates);
    }

    this.approved = revert ? null : false;
    this.evaluate = false
    this.setPendingState();
    return true;
  }

  async reopen() {
    await reopenScheduleRequest(this.id);
    this.approved = null;
    if (this.kind === REQUEST_KIND.post) {
      const replyEmp = this.employees[1];
      replyEmp.employeeId = null;
      replyEmp.msg = '';
      replyEmp.numberOfStars = 0;
    }
    const scheduleStore = useScheduleStore();
    await scheduleStore.refreshScheduleTasks();
    await scheduleStore.loadScheduleRequests(true);
  }

  /**
   * @param {import("@/model/dn-task.js").Task[] } scheduleTasks
   */
  isValid(scheduleTasks) {
    return this.employees.every(x => x.isValid(scheduleTasks));
  }

  /**
   * @private
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   * @param {(st: Date, fi: Date, ttid: number, empid: number) => import("@/model/dn-task.js").Task} createTask
   * @param {boolean} revert
   */
  performChange(scheduleTasks, createTask, revert = false) {
    for (const srEmp of this.employees) {
      if (revert)
        srEmp.revertChange();
      else
        srEmp.changedTasks = srEmp.performChange(scheduleTasks, createTask, this.days);
    }

    this.evaluate = false;
  }

  /**
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   */
  performChangeOnTasks(scheduleTasks) {
    if (!this.isValid(scheduleTasks))
      return false;
    for (const srEmp of this.employees) {
      srEmp.performChange(scheduleTasks, createTask, this.days);
    }

    return true;
    /**
    * @param {Date} st
    * @param {Date} fi
    * @param {number} ttid
    * @param {number} empid
    */
    function createTask(st, fi, ttid, empid) {
      let newTask = new Task({ empid, st, fi, tasktypeId: ttid });
      scheduleTasks.push(newTask);
      return newTask
    }
  }

  /**
 * @param {Map<number,import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
 */
  getAffectedAffinities(taskTypeMap) {
    /** @type {Set<number|null>} */
    const affinities = new Set();
    for (const srEmp of this.employees) {
      for (const requestTask of srEmp.tasks) {
        const tt = taskTypeMap.get(requestTask.tasktypeId);
        if (tt && tt.kind === TASK_KIND.task) {
          affinities.add(tt.affinity);
        }
        if (requestTask.scheduleTask && requestTask.scheduleTask.tasktypeId) {
          const tt = taskTypeMap.get(requestTask.scheduleTask.tasktypeId);
          if (tt && tt.kind === TASK_KIND.task) {
            affinities.add(tt.affinity);
          }
        }
      }
    }
    return affinities;
  }

  getAffectedDates() {
    /** @type {number[]} */
    const affectedDates = [];
    for (const srEmp of this.employees) {
      for (const requestTask of srEmp.tasks) {
        let dtTime = getStartOfDay(requestTask.st).getTime();
        if (!affectedDates.includes(dtTime))
          affectedDates.push(dtTime);
        dtTime = getStartOfDay(requestTask.fi).getTime();
        if (!affectedDates.includes(dtTime))
          affectedDates.push(dtTime);
      }
    }
    return affectedDates.map(x => new Date(x));
  }

  getAffectedIntervals() {
    /** @type {{st:number, fi:number}[]} */
    const intervals = [];
    for (const srEmp of this.employees) {
      for (const requestTask of srEmp.tasks) {
        intervals.push({ st: requestTask.st.getTime(), fi: requestTask.fi.getTime() })
      }
    }

    let i = 0;
    while (i < intervals.length - 1) {
      const interval1 = intervals[i];
      let j = i + 1;
      let modified = false;
      do {
        const interval2 = intervals[j];
        if (interval1.st < interval2.fi && interval1.fi > interval2.st) {
          interval1.st = Math.min(interval1.st, interval2.st);
          interval2.fi = Math.max(interval1.fi, interval2.fi);
          intervals.splice(j, 1);
          modified = true;
          break;
        }

        j += 1;
      } while (j < intervals.length);

      if (!modified)
        i += 1;
    }

    return intervals.map(x => { return { st: new Date(x.st), fi: new Date(x.fi) } });
  }

  /**
   * @private
   * @param {Set<string>} affectedDates
   */
  addToAffectedDates(affectedDates) {
    for (const dt of this.getAffectedDates()) {
      affectedDates.add(getShortDate(dt));
    }
  }

  /**
   * @param {{ id: number; }} employee
   */
  canDelete(employee = null) {
    if (this.canReply(employee)) {
      return false;
    }

    if (!employee) {
      return true;
    }

    if (this.employees[0].employeeId !== employee.id)
      return false;

    return this.approved === null;
  }

  canEvaluate() {
    return true;
  }

  /**
   * @param {{ id: number; }} employee
   */
  canReply(employee = null) {
    if (this.approved !== null)
      return this.isPending;

    if (!employee) {
      if (this.kind === REQUEST_KIND.post) {
        return this.employees.length > 2;
      } else {
        for (const srEmp of this.employees) {
          if (!srEmp.approved) {
            return false;
          }
        }

        return true;
      }
    }

    if (this.employees[0].employeeId === employee.id)
      return false;

    for (let index = 1; index < this.employees.length; index++) {
      if (this.employees[index].employeeId === employee.id || this.employees[index].employeeId === null) {
        return true;
      }
    }

    return false;
  }

  /**
   * @param {boolean} approved
   * @param {{ id: number; }} employee
   */
  canReplyWithAnswerEmp(approved, employee) {
    if (this.kind === REQUEST_KIND.post) {
      for (const srEmp of this.employees) {
        if (srEmp.employeeId === employee.id) {
          return approved === false;
        }
      }
      return approved && this.approved === null;
    } else if (this.kind === REQUEST_KIND.trade) {
      return this.employees.some(x => x.approved !== approved && (x.employeeId === employee.id || x.employeeId === null));
    }

    return false;
  }

  async deleteRequest() {
    await deleteScheduleRequest(this);
  }

  /**
   * @param {number} employeeId
   */
  needsReply(employeeId) {
    if (this.approved !== null)
      return false;
    for (const srEmp of this.employees) {
      if (srEmp.employeeId === employeeId)
        return srEmp.approved === null;
    }

    return false;
  }

  /**
   * @param {{ id: number; }} employee
   */
  getReplyObj(employee) {
    if (employee) {
      const srEmp = this.getEmployee(employee);
      return srEmp ? srEmp : new ScheduleRequestEmployee(employee.id, [], '', 0);
    } else {
      return this;
    }
  }

  /**
   * @param {{ id: number; }} employee
   */
  getEmployee(employee) {
    if (!employee)
      return null;

    return this.employees.find(x => x.employeeId === employee.id);
  }

  getMsgToDisplay() {
    const msg = this.employees[0].msg;
    if (msg)
      return msg;

    switch (this.kind) {
      case REQUEST_KIND.post:
        return 'posted shift';
      case REQUEST_KIND.sick:
        return 'sick';
      case REQUEST_KIND.free:
        return 'time off';
      case REQUEST_KIND.vacation:
        return 'vacation';
      case REQUEST_KIND.trade:
        return 'trade';
    }

    return 'work';
  }

  getReplyMsg() {
    return this.msg;
  }

  get employeesToDisplay() {
    if (this.employees.length <= 1 || this.isTrade)
      return this.employees;
    return [this.employees[1]];
  }

  pickEmployees() {
    return this.employees.slice(2);
  }

  /**
   * @param {ScheduleRequestEmployee} srEmp
   * @param {string} language
   * @param {string} timeZone
   */
  getTasksInfo(srEmp, language, timeZone) {
    const taskTypeMap = useDataStore().taskTypeMap;
    const dtFormat = new Intl.DateTimeFormat(language, { hour: 'numeric', minute: '2-digit', timeZone });

    let taskInfoList = [];
    if (this.employees.length > 1) {
      if (this.isTrade) {
        for (const srEmp2 of this.employees) {
          for (const t of srEmp2.tasks) {
            if (t.scheduleTask !== null && t.scheduleTask.empId === srEmp.employeeId) {
              const tt = taskTypeMap.get(t.tasktypeId);
              taskInfoList.push(getTaskString(t.scheduleTask.st, t.scheduleTask.fi, tt));
            }
          }
        }
      } else {
        for (const t of srEmp.tasks) {
          const tt = taskTypeMap.get(t.tasktypeId);
          taskInfoList.push(getTaskString(t.st, t.fi, tt));
        }
      }
    } else {
      for (const t of srEmp.tasks) {
        const tt = taskTypeMap.get(t.tasktypeId);
        if (t.scheduleTask !== null) {
          if (t.st.getTime() === t.fi.getTime()) {
            taskInfoList.push(getTaskString(t.scheduleTask.st, t.scheduleTask.fi, tt, false));
          } else if (tt.kind === TASK_KIND.break) {
            taskInfoList.push(getTaskString(t.scheduleTask.st, t.scheduleTask.fi, tt) + ' -> ' + getTaskString(t.st, t.fi, tt));
          } else {
            if (t.st.getTime() < t.scheduleTask.st.getTime()) {
              taskInfoList.push(getTaskString(t.st, t.scheduleTask.st, tt, true));
            } else if (t.st.getTime() > t.scheduleTask.st.getTime()) {
              taskInfoList.push(getTaskString(t.scheduleTask.st, t.st, tt, false));
            }

            if (t.fi.getTime() < t.scheduleTask.fi.getTime()) {
              taskInfoList.push(getTaskString(t.fi, t.scheduleTask.fi, tt, false));
            } else if (t.fi.getTime() > t.scheduleTask.fi.getTime()) {
              taskInfoList.push(getTaskString(t.scheduleTask.fi, t.fi, tt, true));
            }
          }
        } else {
          taskInfoList.push(getTaskString(t.st, t.fi, tt, true));
        }
      }
    }

    return taskInfoList;

    /**
     * @param {Date} st
     * @param {Date} fi
     * @param {import("@/model/dn-tasktype.js").TaskType} tt
     */
    function getTaskString(st, fi, tt, add = null) {
      let operation = '';
      if (add !== null) {
        operation = add ? '+ ' : '- ';
      }

      const opText = tt.name.substring(0, 2);
      if (tt.kind === TASK_KIND.break)
        return `${operation}${opText}: ${dtFormat.format(st)} (${getDurationInMinutes(st, fi)} min)`;
      return `${operation}${opText}: ${dtFormat.format(st)} - ${dtFormat.format(fi)}`;
    }
  }

  getApiReply() {
    const reply = { id: this.id, approved: this.approved, msg: this.msg };
    if (this.kind === REQUEST_KIND.post && this.approved) {
      const pickEmp = this.employees[1];
      reply.pick = { id: pickEmp.id, employeeId: pickEmp.employeeId, msg: pickEmp.msg, numberOfStars: pickEmp.numberOfStars };
    }
    return reply;
  }

  /**
  * @param {ScheduleRequest} other
  */
  needToBeRevertedBeforeUpdate(other) {
    if (!this.isPending || !this.isApproved)
      return false;

    for (const srEmp of this.employees) {
      const otherSREmp = other.employees.find(x => x.id === srEmp.id);
      if (otherSREmp.updated.getTime() !== srEmp.updated.getTime()) {
        if (srEmp.employeeId !== otherSREmp.employeeId || otherSREmp.approved !== srEmp.approved)
          return true;
      }
    }

    if (other.updated.getTime() !== this.updated.getTime()) {
      if (this.approved !== other.approved)
        return true;
    }

    return false;
  }

  /**
   * @param {import("@/model/dn-task.js").ScheduleTasks} scheduleTasks
   * @param {import("@/model/dn-tasktype.js").TaskTypes} taskTypes
   * @param {import("@/model/dn-employee.js").Employee} employee
   * @param {boolean|null} approve
   * @param {{id:number;msg:string;numberOfStars?:number}} reply
   * @returns {Promise<{ok:boolean; affectedDates?:Set<string>; toastMsg?:string}>}
   */
  async toggleApproval(scheduleTasks, taskTypes, employee, approve, reply) {
    if (employee) {
      return await this.sendReply(employee, approve, reply);
    }

    if (approve && this.kind === REQUEST_KIND.post && this.approved !== true) {
      if (this.employeeCount > 3) {
        EventBus.emit('selectScheduleRequestPick', this);
        return;
      }
      this.selectPickEmployee(this.employees[2]);
    }

    /** @type {Set<string>} */
    const affectedDates = new Set();
    /** @type {boolean} */
    let ok;
    if (approve) {
      ok = this.approveToggle(scheduleTasks.list, affectedDates);
    } else {
      ok = this.rejectToggle(scheduleTasks.list, affectedDates);
    }

    return { ok, affectedDates };
  }

  /**
  * @private
  * @param {import("@/model/dn-employee.js").Employee} employee
  * @param {boolean|null} approved
  * @param {{id:number;msg:string;numberOfStars?:number;employeeId?:number;approved?:number}} reply
  */
  async sendReply(employee, approved, reply) {
    const srEmp = reply.id ? this.employees.find(x => x.id === reply.id) : reply;
    const isPick = this.kind === REQUEST_KIND.post;
    let toastMsg;
    if (isPick) {
      toastMsg = approved ? 'Shift picked' : 'Shift pick removed';
    } else {
      toastMsg = 'Trade has been ' + (approved ? 'accepted.' : 'rejected.');
    }

    let ok = true;
    try {
      let employeeId = employee.id;
      const data = { id: srEmp.id, kind: this.kind, employeeId: employeeId, approved: approved, msg: srEmp.msg, stars: srEmp.numberOfStars };
      await updateScheduleRequestEmployee(this.id, data);
      srEmp.employeeId = employeeId;
      srEmp.approved = approved;
      const scheduleStore = useScheduleStore();
      scheduleStore.loadScheduleRequests();
      if (approved && isPick)
        EventBus.emit("shiftPicked");
    } catch (error) {
      toastMsg = 'Something went wrong.';
      ok = false;
      console.log(error);
    }
    return { toastMsg, ok };
  }

  /**
   * @param {ScheduleRequest} other
   */
  update(other) {
    if (other.id !== this.id)
      return;

    for (const srEmp of this.employees) {
      const otherSREmp = other.employees.find(x => x.id === srEmp.id);
      if (otherSREmp.updated.getTime() !== srEmp.updated.getTime()) {
        srEmp.approved = otherSREmp.approved;
        srEmp.numberOfStars = otherSREmp.numberOfStars;
        srEmp.employeeId = otherSREmp.employeeId;
        srEmp.msg = otherSREmp.msg;
        srEmp.updated = otherSREmp.updated;
      }
    }

    if (other.updated.getTime() !== this.updated.getTime()) {
      this.msg = other.msg;
      this.approved = other.approved;
      this.updated = other.updated;
    }
  }
}

export class ScheduleRequestEmployee {

  /**
   * @param {number|null} employeeId
   * @param {ScheduleRequestTask[]} tasks
   * @param {string} msg
   * @param {number} numberOfStars
   */
  constructor(employeeId, tasks, msg, numberOfStars, approved = null, updated = undefined, id = undefined) {
    /** @type {number|null} */
    this.id = id;
    /** @type {number|null} */
    this.employeeId = employeeId;
    /** @type {Date | undefined} */
    this.updated = updated;
    /** @type {boolean | null} */
    this.approved = approved;
    /** @type {string} */
    this.msg = msg;
    /** @readonly @type {ScheduleRequestTask[]} */
    this.tasks = tasks;
    /** @type {number} */
    this.numberOfStars = numberOfStars;
    /** @readonly @type {Date[]} 1 or 2 elements. */
    this.starts = [];
    /** @type {import("@/model/dn-task.js").Task[]} */
    this.changedTasks = undefined;
  }

  revertChange() {
    for (const t of this.changedTasks) {
      if (t.id <0) {
        t.toDelete();
      } else {
        const requestTask = this.tasks.find(x => x.scheduleTask !== null && x.scheduleTask.id === t.id);
        t.resurrect();
        t.st = new Date(requestTask.scheduleTask.st);
        t.fi = new Date(requestTask.scheduleTask.fi);
        t.tasktypeId = requestTask.scheduleTask.tasktypeId;
        t.empid = requestTask.scheduleTask.empId;
        t.confirmChanges();
      }
    }

    this.changedTasks = undefined;
  }

  /**
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   */
  isValid(scheduleTasks) {
    for (const t of this.tasks) {
      if (t.scheduleTask !== null && !scheduleTasks.some(x => x.id === t.scheduleTask.id))
        return false;
    }
    return true;
  }

  /**
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   * @param {(st: Date, fi: Date, ttid: number, empid: number) => import("@/model/dn-task.js").Task} createTask
   * @param {number} days
   */
  performChange(scheduleTasks, createTask, days) {
    let changedTasks = [];
    for (let d = 0; d < days; d++) {
      for (const requestTask of this.tasks) {
        const st = d > 0 ? addDays(requestTask.st, d) : requestTask.st;
        const fi = d > 0 ? addDays(requestTask.fi, d) : requestTask.fi;
        let task;
        if (requestTask.scheduleTask === null) {
          task = createTask(st, fi, requestTask.tasktypeId, this.employeeId);
        } else {
          task = scheduleTasks.find(x => x.id === requestTask.scheduleTask.id);
          task.resurrect();
          task.st = new Date(st);
          task.fi = new Date(fi);
          task.tasktypeId = requestTask.tasktypeId;
          task.empid = this.employeeId;
          if (task.st.getTime() === task.fi.getTime()) {
            task.toDelete();
          }
        }

        changedTasks.push(task);
      }

    }

    return changedTasks;
  }
}

export class ScheduleRequestTask {

  /**
   * @param {Date} st
   * @param {Date} fi
   * @param {number} tasktypeId
   * @param {null|ScheduleRequestScheduleTask} scheduleTask
   */
  constructor(st, fi, tasktypeId, scheduleTask = null) {
    /** @readonly @type {Date} */
    this.st = st;
    /** @readonly  @type {Date} */
    this.fi = fi;
    /** @readonly @type {number} */
    this.tasktypeId = tasktypeId;
    /** @readonly @type {null|ScheduleRequestScheduleTask} */
    this.scheduleTask = scheduleTask;
  }
}

export class ScheduleRequestScheduleTask {

  /**
   * @param {number} id
   * @param {number} empId,
   * @param {Date} st
   * @param {Date} fi
   * @param {number} tasktypeId
   */
  constructor(id, empId, st, fi, tasktypeId) {
    /** @readonly @type {number} */
    this.id = id;
    /** @readonly @type {number} */
    this.empId = empId;
    /** @readonly @type {Date} */
    this.st = st;
    /** @readonly  @type {Date} */
    this.fi = fi;
    /** @readonly @type {number} */
    this.tasktypeId = tasktypeId;
  }
}

/**
 * @param {ScheduleRequest} scheduleRequest
 */
async function deleteScheduleRequest(scheduleRequest) {
  await fetchAndCheckJson(`schedule-request/${scheduleRequest.id}?updated=${scheduleRequest.updated.toISOString()}`, 'DELETE');
}

/**
 * @param {number[]} idList
 */
export async function deleteScheduleRequests(idList) {
  await fetchAndCheckJson(`schedule-request/0?list=${idList.join()}`, 'DELETE');
}

/**
 * @param {number} ccId
 * @param {number} employeeId
 * @param {boolean | undefined} onlyOpenRequests
 * @param {Date | undefined} st
 * @param {Date | undefined} fi
 * @return {Promise<ScheduleRequest[]>}
 */
export async function getScheduleRequest(ccId, employeeId = undefined, onlyOpenRequests = undefined, st = undefined, fi = undefined) {
  const employeeQuery = employeeId ? '&employeeId=' + employeeId : '';

  let openQuery = '';
  if (onlyOpenRequests)
    openQuery = '&open=1'

  let stQuery = '';
  if (st !== undefined)
    stQuery = '&st=' + st.toISOString();

  let fiQuery = '';
  if (fi != undefined)
    fiQuery = '&fi=' + fi.toISOString();

  return await fetchAndCheckJson(`schedule-request?ccId=${ccId}${employeeQuery}${openQuery}${stQuery}${fiQuery}`, 'GET').then(x => x.map(convertScheduleRequestFromApi));
}

/**
 * @param {number} id
 * @param {{id:number; kind:number; employeeId:number; approved: null|boolean; msg: string; stars: number}} data
 */
async function updateScheduleRequestEmployee(id, data) {
  await fetchAndCheckJson(`schedule-request/${id}`, 'PATCH', data);
}

/**
 * @param {number} id
 */
async function reopenScheduleRequest(id) {
  await fetchAndCheckJson(`schedule-request/${id}`, 'PATCH');
}

/**
 * @param {ScheduleRequest} scheduleRequest
 */
export async function patchScheduleRequestEdit(scheduleRequest) {
  await fetchAndCheckJson(`schedule-request/${scheduleRequest.id}?edit=1`, 'PATCH', convertScheduleRequestToApi(scheduleRequest));
}

/**
 * @param {ScheduleRequest} request
 * @return {Promise<ScheduleRequest>}
 */
export async function postScheduleRequest(request) {
  return await fetchAndCheckJson('schedule-request', 'POST', convertScheduleRequestToApi(request)).then(convertScheduleRequestFromApi);
}

/**
 * @param {ScheduleRequest[]} requests
 */
export async function postScheduleRequests(requests) {
  const data = requests.map(x => convertScheduleRequestToApi(x));
  return await fetchAndCheckJson('schedule-request?multiple=1', 'POST', data);
}

/**
 * @param {ScheduleRequest} r
 * @return {{approved:boolean|null; callCenterId:number; kind:number, days:number|null, msg:string, employees:{approved: boolean|null; employeeId:number;msg: string; stars: number;
 * tasks: ScheduleRequestTask[]}[]}}
 */
function convertScheduleRequestToApi(r) {
  const employees = [];
  for (const re of r.employees) {
    employees.push({ approved: re.approved, employeeId: re.employeeId, msg: re.msg, stars: re.numberOfStars, tasks: re.tasks });
  }

  return { approved: r.approved, callCenterId: r.callCenterId, kind: r.kind, days: r.days, msg: r.msg, employees: employees };
}

/**
 * @param {{id:number; approved:boolean|null; kind: number, days:number|null; callCenterId:number; msg:string, created:string, updated:string,
 * employees:{id:number; approved: boolean|null; employeeId:number|null;msg: string; stars: number; updated: string;
 * tasks: { st:string, fi:string, tasktypeId:number, scheduleTask:null|{id:number, empId:number, st:string, fi:string, tasktypeId:number} }[]}[]}} r
 */
function convertScheduleRequestFromApi(r) {
  const employees = [];
  for (const re of r.employees) {
    const tasks = [];
    for (const rt of re.tasks) {
      let scheduleTask = null;
      if (rt.scheduleTask) {
        scheduleTask = new ScheduleRequestScheduleTask(rt.scheduleTask.id, rt.scheduleTask.empId, new Date(rt.scheduleTask.st), new Date(rt.scheduleTask.fi), rt.scheduleTask.tasktypeId);
      }

      tasks.push(new ScheduleRequestTask(new Date(rt.st), new Date(rt.fi), rt.tasktypeId, scheduleTask));
    }

    employees.push(new ScheduleRequestEmployee(re.employeeId, tasks, re.msg, re.stars, re.approved, new Date(re.updated), re.id));
  }

  return new ScheduleRequest(employees, r.callCenterId, r.kind, r.days, r.msg, r.approved, r.id, new Date(r.created), new Date(r.updated));
}

export const REQUEST_KIND = createRequestKindEnum();

function createRequestKindEnum() {
  const enumObject = {
    unavailability: -3,
    availability: -2,
    pick: -1,
    work: 0,
    sick: 1,
    vacation: 2,
    post: 3,
    trade: 4,
    free: 5,
    isAvailabilityRequest: function (/** @type {number} */ kind) {
      return kind === this.availability || kind === this.unavailability;
    }
  };

  return Object.freeze(enumObject);
}

/**
 * @param {import("@/model/dn-employee-schedule").EmployeeSchedule} schedule
 * @param {Date} dt
 * @param {{ msg:string; stars:number; days:number|null; st: Date; fi: Date; }?} requestConfig
 */
export function createPostRequest(schedule, dt, requestConfig = undefined) {
  const original = getScheduleRequestBounds(schedule, dt)
  if (!requestConfig) {
    requestConfig = { msg: '', stars: 0, days: null, st: original.st, fi: original.fi };
  }
  const tasksToReduce = [];
  const publishedTasks = [];
  for (const t of original.tasks.concat(original.breaks)) {
    if (t.overlaps(original.st, original.fi)) {
      if (t.isContainedIn(original)) {
        publishedTasks.push(createScheduleRequestTask(t.st, t.fi, t));
      } else {
        const st = t.st < original.st ? original.st : t.st;
        const fi = t.fi > original.fi ? original.fi : t.fi;
        publishedTasks.push(new ScheduleRequestTask(st, fi, t.tasktypeId));

        const usedExistingTask = t.st < original.st;
        if (usedExistingTask) {
          tasksToReduce.push(createScheduleRequestTask(t.st, original.st, t));
        }

        if (t.fi > original.fi) {
          if (usedExistingTask) {
            tasksToReduce.push(new ScheduleRequestTask(original.fi, t.fi, t.tasktypeId));
          } else {
            tasksToReduce.push(createScheduleRequestTask(original.fi, t.fi, t));
          }
        }
      }
    }
  }

  return createRequestCommon(schedule, requestConfig, REQUEST_KIND.post, tasksToReduce, publishedTasks);
}

/**
* @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} schedule
* @param {{msg:string; stars:number; days:number|null; }} requestConfig
* @param {number} requestKind
* @param {ScheduleRequestTask[]} tasks
* @param {ScheduleRequestTask[]|null} publishedTasks
*/
export function createRequestCommon(schedule, requestConfig, requestKind, tasks, publishedTasks = null) {
  const employees = [];
  employees.push(new ScheduleRequestEmployee(schedule.emp.id, tasks, requestConfig.msg, requestConfig.stars, true));
  if (publishedTasks !== null) {
    employees.push(new ScheduleRequestEmployee(null, publishedTasks, '', 0));
  }

  return new ScheduleRequest(employees, schedule.emp.ccid, requestKind, requestConfig.days);
}

/**
* @param {Date} st
* @param {Date} fi
* @param {import("@/model/dn-task.js").Task} t
*/
export function createScheduleRequestTask(st, fi, t) {
  return new ScheduleRequestTask(st, fi, t.tasktypeId, new ScheduleRequestScheduleTask(t.id, t.empid, t.st, t.fi, t.tasktypeId));
}

/**
* @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
* @param {Date} dt
*/
export function getScheduleRequestBounds(employeeSchedule, dt) {
  const shiftBounds = getShiftBounds(dt, employeeSchedule.tasks);
  const tasks = employeeSchedule.tasks.filter(t => t.st >= shiftBounds.st && t.st < shiftBounds.fi);
  sortDateIntervals(tasks);
  const breaks = employeeSchedule.breaks.filter(t => t.st >= shiftBounds.st && t.st < shiftBounds.fi);
  sortDateIntervals(breaks);
  const hasShift = shiftBounds.fi > shiftBounds.st;
  if (!hasShift) {
    shiftBounds.st = new Date(dt);
    shiftBounds.st.setMinutes(employeeSchedule.emp.absenceStart());
    shiftBounds.fi = new Date(shiftBounds.st.getTime() + employeeSchedule.emp.absenceDuration * 60000);
  }

  return { hasShift, st: shiftBounds.st, fi: shiftBounds.fi, tasks, breaks };
}
