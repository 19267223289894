import moment from 'moment-timezone';
import { getStartOfDay, getShortDate } from "@/js/dn-helper.js";

/** @typedef {{dt:Date;dtLocal:Date;shortDate:string}} TimezoneDayInfo*/

/**
 * Gets the closest start of the day in the given time zone.
 * @param {Date} dt
 * @param {string} timezone
 */
export function getClosestStartOfDayTZ(dt, timezone) {
  if (!timezone) { return dt; }
  return moment.tz(dt, timezone).add(12, 'hours').startOf('day').toDate();
}

/**
 * @param {Date} dt
 * @param {string} timezone
 */
export function getLocalOffset(dt, timezone) {
  if (!timezone) { return 0; }
  const localUTCOffset = moment(dt).utcOffset();
  const tzUTCOffset = moment.tz(dt, timezone).utcOffset()
  return tzUTCOffset - localUTCOffset;
}

/**
 * @param {Date} dtLocal 
 * @param {string} timezone
 * @returns {TimezoneDayInfo}
 */
export function getDateTimezoneInfo(dtLocal, timezone) {
  const shortDate = getShortDate(dtLocal);
  const dt = timezone ? moment.tz(shortDate, timezone).toDate() : dtLocal;
  return { dt, dtLocal, shortDate };
}

/**
 * @param {TimezoneDayInfo} day
 * @param {number} hours
 * @param {number} minutes
 * @param {string} timezone
 */
export function getDateTimeInTimezone(day, hours, minutes, timezone) {
  if (timezone) {
    return moment.tz(day.shortDate, timezone).hours(hours).minutes(minutes).toDate();
  }
  const dt = new Date(day.dtLocal);
  dt.setHours(hours, minutes);
  return dt;
}

/**
 * @param {Date} dt
 * @param {string} timezone
 */
export function getTimeOfDay(dt, timezone) {
  /** @type {number} */
  let hours;
  /** @type {number} */
  let minutes;
  if (timezone) {
    const m = moment.tz(dt, timezone);
    hours = m.hours();
    minutes = m.minutes();
  } else {
    hours = dt.getHours();
    minutes = dt.getMinutes();
  }
  return { hours, minutes };
}

/**
 * @param {Date} dt
 * @param {string} timezone
 */
export function getDateOfInstant(dt, timezone) {
  if (timezone) {
    const m = moment.tz(dt, timezone);
    return new Date(m.year(), m.month(), m.date());
  } else {
    return getStartOfDay(dt);
  }
}