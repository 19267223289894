import { employeeFromDto } from '@/model/dn-employee.js';
import { fetchAndCheckJson } from '@/js/dn-fetch.js';

const ACCESS_RIGHT = createAccessRightEnum();

/**
 * @typedef {{id:number;admin:boolean;email:string;contactcenterIds?:number[];api:boolean;forecasting:string;name:string;reports:string;scheduling:string;settings:string;initials:string;colorId:number;tagIdList?:number[]}} UserDto
 */

/**
 * @param {string} email
 * @param {boolean} api
 */
export function createNewUser(email, api) {
  if (api) {
    return new User({
      id: -1, admin: false, api: api, email: email,
      forecasting: '', name: 'API', reports: '', scheduling: '', settings: '', initials:null, colorId:null
    });
  }
  return new User({
    id: -1, admin: false, api: api, email: email,
    forecasting: 'w', name: '', reports: 'r', scheduling: 'w', settings: 'r', initials:null, colorId:null
  });
}

export async function getUsers() {
  /** @type {UserDto[]} */
  const result = await fetchAndCheckJson('users', 'GET');
  return result.map(r => new User(r));
}

export async function getCurrentUser() {
  const user = await fetchAndCheckJson('users?currentUser=1', 'GET');
  
  return new LoggedInUser(user);
}

/**
 * @param {number} id
 * @param {number} authMethod
 * @param {number} loginId
 * @param {string} secret
 * @param {number} reqNotify
 */
export async function patchUser(id, authMethod, loginId, secret, reqNotify = undefined) {
  /** @type {{ok:boolean}} */
  const result = await fetchAndCheckJson('users/' + id, 'PATCH', { authMethod, loginId, secret, reqNotify });
  return result.ok;
}

/**
 * @param {User[]} users
 */
export async function saveUsers(users) {
  const toDelete = [];
  const toCreate = [];
  const toPatch = [];
  for (const u of users) {
    if (u.isToDelete) {
      toDelete.push(u.id);
    } else if (u.hasChanges) {
      if (u.id === -1) {
        toCreate.push({
          name: u.name, email: u.email, password: u.pwd, contactCenterIds: u.contactcenterIds, tagIdList: u.tagIdList,
          forecasting: u.forecasting, scheduling: u.scheduling, reports: u.reports, settings: u.settings,
          notify: u.notify, notifyEmail: u.notifyEmail, api: u.api, initials: u.initials, colorId: u.colorId
        });
      } else {
        toPatch.push({
          id: u.id, name: u.name, email: u.email, contactCenterIds: u.contactcenterIds, tagIdList: u.tagIdList,
          forecasting: u.forecasting, scheduling: u.scheduling, reports: u.reports, settings: u.settings, initials: u.initials, colorId: u.colorId
        });
      }
    }
  }
  /**
   * @type {{created:{id:number, email:string, notifyResult:boolean|null}[], errors:{email:string; emailAlreadyExists:boolean;}[]}}
   */
  const result = await fetchAndCheckJson('users', 'POST', { toDelete: toDelete, toCreate: toCreate, toPatch: toPatch });
  let i = users.length - 1;
  while (i >= 0) {
    const u = users[i];
    if (u.isToDelete) {
      users.splice(i, 1);
    } else if (u.hasChanges) {
      if (u.id === -1) {
        for (const createdResult of result.created) {
          if (createdResult.email === u.email) {
            u.id = createdResult.id;
            break;
          }
        }
      }
      if (!result.errors.some(x => x.email === u.email)) {
        u.confirmChanges();
      }
    }

    i--;
  }

  return result;
}

export class User {
  /**
   * @param {UserDto} user
   */
  constructor(user) {
    /** @type {number} */
    this.id = user.id;
    /** @private @type {string} */
    this._name = user.name;
    /** @private @type {string} */
    this._email = user.email
    /** @protected @type {number[]} */
    this._contactcenterIds = user.contactcenterIds ? user.contactcenterIds : [];
    /** @private @type {string} */
    this._forecasting = user.forecasting
    /** @private @type {string} */
    this._scheduling = user.scheduling
    /** @private @type {string} */
    this._reports = user.reports
    /** @private @type {string} */
    this._settings = user.settings
    /** @private @type {string} */
    this._initials = user.initials;
    /** @private @type {number} */
    this._colorId = user.colorId;
    /** @readonly @type {boolean} */
    this.admin = user.admin;
    /** @type {boolean} */
    this.api = user.api;
    /** @private @type {number[]} */
    this._tagIdList = user.tagIdList ? user.tagIdList : [];
    /** @private @type {string} */
    this._pwd = '';
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {boolean} */
    this._isToDelete = false;
    /** @type {boolean|undefined} */
    this.notify = undefined;
    /** @type {string|undefined} */
    this.notifyEmail = undefined;
  }

  get colorId() {
    return this._colorId;
  }
  set colorId(newValue) {
    this._colorId = newValue;
    this.markAsHasChanges();
  }

  get initials() {
    return this._initials;
  }
  set initials(newValue) {
    this._initials = newValue;
    this.markAsHasChanges();
  }

  get name() {
    return this._name
  }
  set name(newValue) {
    this._name = newValue
    this.markAsHasChanges();
  }

  get email() {
    return this._email
  }
  set email(newValue) {
    this._email = newValue
    this.markAsHasChanges();
  }

  get pwd() {
    return this._pwd
  }

  set pwd(newValue) {
    this._pwd = newValue
    this.markAsHasChanges();
  }

  get contactcenterIds() {
    return this._contactcenterIds
  }
  set contactcenterIds(newValue) {
    this._contactcenterIds = newValue
    this.markAsHasChanges();
  }

  get tagIdList() {
    return this._tagIdList;
  }
  set tagIdList(newValue) {
    this._tagIdList = newValue;
    this.markAsHasChanges();
  }

  getNumAccessRight(val) {
    let x = 0
    if (val == ACCESS_RIGHT.readonly) { x = 1 }
    if (val == ACCESS_RIGHT.write) { x = 2 }
    return x
  }
  getStringAccessRight(val) {
    let x = ACCESS_RIGHT.none
    if (val == 1) { x = ACCESS_RIGHT.readonly }
    if (val == 2) { x = ACCESS_RIGHT.write }
    return x
  }
  getNumAccessRightScheduling(val) {
    let x = 0
    if (val == ACCESS_RIGHT.readonly) { x = 1 }
    if (val == ACCESS_RIGHT.supervisor) { x = 2 }
    if (val == ACCESS_RIGHT.write) { x = 3 }
    return x
  }
  getStringAccessRightScheduling(val) {
    let x = ACCESS_RIGHT.none
    if (val == 1) { x = ACCESS_RIGHT.readonly }
    if (val == 2) { x = ACCESS_RIGHT.supervisor }
    if (val == 3) { x = ACCESS_RIGHT.write }
    return x
  }

  get schedulingNum() {
    return this.getNumAccessRightScheduling(this._scheduling)
  }
  get forecastingNum() {
    return this.getNumAccessRight(this._forecasting)
  }
  get settingsNum() {
    return this.getNumAccessRight(this._settings)
  }
  get reportsNum() {
    return this.getNumAccessRight(this._reports)
  }

  set forecastingNum(newValue) {
    this._forecasting = this.getStringAccessRight(newValue)
    this.markAsHasChanges();
  }

  set schedulingNum(newValue) {
    this._scheduling = this.getStringAccessRightScheduling(newValue)
    this.markAsHasChanges();
  }
  set reportsNum(newValue) {
    this._reports = this.getStringAccessRight(newValue)
    this.markAsHasChanges();
  }
  set settingsNum(newValue) {
    this._settings = this.getStringAccessRight(newValue)
    this.markAsHasChanges();
  }

  get scheduling() {
    return this._scheduling
  }
  get forecasting() {
    return this._forecasting
  }
  get settings() {
    return this._settings
  }
  get reports() {
    return this._reports
  }
  set forecasting(newValue) {
    this._forecasting = newValue
    this.markAsHasChanges();
  }

  set scheduling(newValue) {
    this._scheduling = newValue
    this.markAsHasChanges();
  }
  set reports(newValue) {
    this._reports = newValue
    this.markAsHasChanges();
  }
  set settings(newValue) {
    this._settings = newValue
    this.markAsHasChanges();
  }
  get hasChanges() {
    return this._hasChanges;
  }
  confirmChanges() {
    this._hasChanges = false;
    this.notify = undefined;
  }

  get isToDelete() {
    return this._isToDelete
  }
  markAsHasChanges() {
    this._hasChanges = true;
  }
  toDelete() { this._isToDelete = true }
}

export class LoggedInUser extends User {
  constructor(user) {
    super(user)
    /** @type {boolean} */
    this.isSuperAdmin = user.isSuperAdmin;
    /** @private @type {import("../model/dn-employee.js").Employee|null} */
    this._employee = null;

    /** @type {string|null} */
    this.endTime = user.endTime;

    /** @type {number[]} */
    const features = user.features;

    /** @readonly @type {boolean} */
    this.sms = features.includes(2);

    /** @readonly @private @type {boolean} */
    this._adherence = features.includes(3);

    /** @type {number} */
    this.authMethod = user.authMethod !== null ? user.authMethod : 0;

    /** @type {boolean} */
    this.isEmail = user.isEmail !== false;

    /** @type {number} */
    this.useMode = user.useMode;

    /** @type {boolean} */
    this.useDepartments = user.useDepartments;

    /** @type {number} */
    this.reqNotify = user.reqNotify !== null ? user.reqNotify : 0;


    if (user.employee) {
      this.employee = employeeFromDto(user.employee);
    }

    /** @type {string} */
    const customerTimezone = user.customerTimezone;

    /** @readonly @type {string} */
    this.timezone = user.tzMode > 0 ? customerTimezone : null;

    /** @readonly @type {string} */
    this.timezoneUI = this.isAgent && user.tzMode === 2 ? customerTimezone : undefined;
  }

  get hasAdherence() {
    return this._adherence && !this._employee;
  }

  get hasAccessForecasting() {
    return this.forecasting !== ACCESS_RIGHT.none;
  }
  get hasAccessReports() {
    return this.reports !== ACCESS_RIGHT.none;
  }
  get hasAccessScheduling() {
    return this.scheduling !== ACCESS_RIGHT.none;
  }
  get hasAccessSettings() {
    return this.settings !== ACCESS_RIGHT.none;
  }

  get canEditForecasting() {
    return this.forecasting === ACCESS_RIGHT.write;
  }
  get canEditScheduling() {
    return (this.scheduling === ACCESS_RIGHT.write) || (this.scheduling === ACCESS_RIGHT.supervisor);
  }
  get canEditSettings() {
    return this.settings === ACCESS_RIGHT.write;
  }
  get isSupervisor() {
    return this.scheduling === ACCESS_RIGHT.supervisor;
  }

  get isAgent() {
    return this.employee !== null;
  }

  //if not null, agent login
  get employee() {
    return this._employee
  }
  set employee(newValue) {
    this._employee = newValue
    if (newValue)
      this._contactcenterIds = [newValue.ccid]
  }
}

/**
 * @param {string} current
 * @param {string} text
 */
export function getSecretFormatting(current, text) {
  let password2WithMask = '';
  let password2 = '';
  for (const c of text) {
    if (c >= '0' && c <= '9') {
      if (password2WithMask.length === 3) {
        password2WithMask += ' - '
      }
      password2WithMask += c;
      password2 += c;
    }
  }
  if (current === password2WithMask) {
    password2WithMask += ' ';
  }

  return { password2, password2WithMask };
}

function createAccessRightEnum() {
  const enumObject = {
    none: '',
    readonly: 'r',
    supervisor: 's',
    write: 'w',
  };

  return Object.freeze(enumObject);
}
