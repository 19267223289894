import { fetchAndCheckJson } from '@/js/dn-fetch.js';

export const IMPORT_CONFIG_KIND = createImportConfigEnum();

/**
 * @param {SwitchConfig[]} switchConfigs
 */
export function addNewSwitchConfig(switchConfigs) {
  let minId = 0;
  for (const sc of switchConfigs) {
    minId = Math.min(sc.id, minId);
  }

  switchConfigs.push(new SwitchConfig({ id: minId - 1, active: true, timezone: 'UTC', importConfigs: [] }));
}

function createImportConfigEnum() {
  const enumObject = {
    apiCalls: 0,
    startel: 1,
    peoplePraise: 2,
    apiAgentEvents: 3,
    fileImportCalls: 4,
    fileImportAgentEvents: 5,
  };

  return Object.freeze(enumObject);
}

export function getImportConfigKinds() {
  /** @type {{id:number, name:string}[]} */
  const switchKinds = [];
  switchKinds.push({ id: IMPORT_CONFIG_KIND.apiCalls, name: 'simTree api' });
  switchKinds.push({ id: IMPORT_CONFIG_KIND.startel, name: 'startel cmc api' });
  switchKinds.push({ id: IMPORT_CONFIG_KIND.peoplePraise, name: 'People Praise' });
  switchKinds.push({ id: IMPORT_CONFIG_KIND.apiAgentEvents, name: 'simTree api' });
  switchKinds.push({ id: IMPORT_CONFIG_KIND.fileImportCalls, name: 'file import' });
  switchKinds.push({ id: IMPORT_CONFIG_KIND.fileImportAgentEvents, name: 'file import' });
  return switchKinds;
}

/**
 * @param {number} importConfigKind
 */
function isImportOfCallData(importConfigKind) {
  switch (importConfigKind) {
    case IMPORT_CONFIG_KIND.apiCalls:
    case IMPORT_CONFIG_KIND.startel:
    case IMPORT_CONFIG_KIND.fileImportCalls:
      return true;
    default:
      return false;
  }
}

/**
 * @typedef {{switchId:number, kind:number, url:string|null, username:string|null, pwd:string|null, lastTime:Date|null, errorMsg?:string|null, calcWrap:boolean|null; minSystemHold:number|null}} ImportConfigtDto
 */

/**
 * @typedef {{id:number;active:boolean;timezone:string; name?:string;importConfigs:ImportConfigtDto[]; deletedImportConfigKinds?:number[]}} SwitchConfigDto
 */

export async function getSwitches() {
  /** @type {SwitchConfigDto[]} */
  const switches = await fetchAndCheckJson(`switch-config`, 'GET');
  return switches.map(x => new SwitchConfig(x));
}

/**
 * @param {SwitchConfigDto} data
 */
async function postSwitch(data) {
  await fetchAndCheckJson(`switch-config`, 'POST', data);
}

/**
 * @param {SwitchConfigDto} data
 */
async function patchSwitch(data) {
  await fetchAndCheckJson(`switch-config/${data.id}`, 'PATCH', data);
}

export class SwitchConfig {
  /**
   * @param {SwitchConfigDto} dto
   */
  constructor(dto) {
    /** @readonly @type {number} */
    this.id = dto.id;
    /** @private @type {string} */
    this._name = dto.name;
    /** @private @type {string} */
    this._timezone = dto.timezone;
    /** @private @type {boolean} */
    this._active = dto.active;
    /** @type {ImportConfigtDto[]} */
    this.importConfigs = dto.importConfigs;
    /** @private @type {Set<number>} */
    this._deletedImportConfigKinds = new Set();
    /** @private @type {Map<number, ImportConfigtDto>} */
    this._changedImportConfigs = new Map();
    /** last time for call data @type {Date|null} */
    let lastTime = null;
    for (const ic of dto.importConfigs) {
      if (ic.lastTime) {
        ic.lastTime = new Date(ic.lastTime);
        if (isImportOfCallData(ic.kind)) {
          if (lastTime === null || ic.lastTime < lastTime) {
            lastTime = ic.lastTime;
          }
        }
      }
    }

    /** @readonly @type {Date|null} */
    this.lastTime = lastTime;
    if (dto.id === null) {
      /** @private @type {boolean} */
      this._hasChanges = true;
    }
  }

  get active() {
    return this._active;
  }
  set active(value) {
    if (this._active !== value) {
      this._hasChanges = true;
      this._active = value;
    }
  }

  get hasChanges() {
    return this._hasChanges === true;
  }

  get name() {
    return this._name;
  }
  set name(value) {
    if (this._name !== value) {
      this._hasChanges = true;
      this._name = value;
    }
  }

  get timezone() {
    return this._timezone;
  }
  set timezone(value) {
    if (this._timezone !== value) {
      this._hasChanges = true;
      this._timezone = value;
    }
  }

  /**
   * @param {ImportConfigtDto} dto
   */
  addmportConfig(dto) {
    this.importConfigs.push(dto);
    this._changedImportConfigs.set(dto.kind, dto);
    this.markAsHasChanges();
  }

  copy() {
    return new SwitchConfig(this.toDto());
  }

  /**
   * @param {number} kind
   */
  deleteImportConfig(kind) {
    const index = this.importConfigs.findIndex(x => x.kind === kind);
    this._deletedImportConfigKinds.add(kind);
    this.importConfigs.splice(index, 1);
    this.markAsHasChanges();
  }

  /**
   * @param {ImportConfigtDto} dto
   */
  updateImportConfig(dto) {
    const index = this.importConfigs.findIndex(x => x.kind === dto.kind);
    this.importConfigs[index] = dto;
    this._changedImportConfigs.set(dto.kind, dto);
    this.markAsHasChanges();
  }

  /**
   * @param {boolean} calls
   */
  filteredImportConfigs(calls) {
    return this.importConfigs.filter(x => isImportOfCallData(x.kind) === calls);
  }

  /**
   * @param {{id:number, name:string}[]} all
   * @param {boolean} calls
   */
  possibleImportConfigsToAdd(all, calls) {
    return all.filter(x => isImportOfCallData(x.id) === calls && !this.importConfigs.some(y => y.kind === x.id));
  }

  markAsHasChanges() {
    this._hasChanges = true;
  }

  orderById() {
    return this.importConfigs.some(x => x.kind === IMPORT_CONFIG_KIND.startel);
  }

  /** @private @returns {SwitchConfigDto} */
  toDto() {
    const importConfigs = this.importConfigs.map(x => ({ ...x }));
    return { id: this.id, name: this.name, timezone: this.timezone, active: this.active, importConfigs }
  }

  /** @private */
  toSave() {
    /** @type {ImportConfigtDto[]} */
    const importConfigs = [];
    for (const ic of this._changedImportConfigs.values()) {
      importConfigs.push(ic);
    }

    /** @type {SwitchConfigDto} */
    const dto = { id: this.id, name: this.name, timezone: this.timezone, active: this.active, importConfigs };
    if (this._deletedImportConfigKinds.size > 0) {
      dto.deletedImportConfigKinds = Array.from(this._deletedImportConfigKinds);
    }

    return dto;
  }

  async save() {
    const dto = this.toSave();
    if (this.id <= 0) {
      await postSwitch(dto);
    } else {
      await patchSwitch(dto);
    }
    this._hasChanges = false;
  }
}
