const UNDO_STATE = createUndoState();
let revCounter = 0
const revisionMap = new Map()
let lastIncrementUndoOrRedo = UNDO_STATE.undo
let newSelectionPending = false


export function incrementUndoRevision(direct=false){
    if(direct){
        if(revCounter<revisionMap.size-1){
            if(lastIncrementUndoOrRedo == UNDO_STATE.redo){revCounter++}
        }
        lastIncrementUndoOrRedo = UNDO_STATE.redo
    }else{
        if(revisionMap.get(revCounter)&&revisionMap.get(revCounter).undo.size>0){newSelectionPending = true}
    }   
}

//undo
export function decrementUndoRevision(){  
    if(revisionMap.has(revCounter)){
        if(revCounter>0){
          if(lastIncrementUndoOrRedo == UNDO_STATE.undo){revCounter--}  
        }
        lastIncrementUndoOrRedo = UNDO_STATE.undo
    }
}

export function resetUndo(){
    revCounter = 0
    revisionMap.clear
    newSelectionPending = false
}


export function addUndoTransaction(id,st,fi,tasktypeId,isNew=null,isDeleted=null){
    if(id==-1){return}
    if(newSelectionPending){
        revCounter=revisionMap.size
        newSelectionPending = false}
    
    if(!revisionMap.has(revCounter)){revisionMap.set(revCounter,{undo:new Map(),redo:new Map()})}
    else if(revCounter<revisionMap.size-1){
        //console.log('remove older data for ' +id);
        for(let i=revCounter;i<revisionMap.size-1;i++){
            let x = revisionMap.get(i)
            if(x.undo.get(id)){x.undo.delete(id)}
            if(x.redo.get(id)){x.redo.delete(id)}
            //console.log('deleted rev' +i);
        } 
    }

    const currentMap =revisionMap.get(revCounter).undo
    if(!currentMap.has(id)){
        currentMap.set(id,{st:st,fi:fi,tasktypeId:tasktypeId,isNew:isNew,isDeleted:isDeleted})
    }else{
        let x = currentMap.get(id)
        if(st&&!x.st){x.st = st}
        if(fi&&!x.fi){x.fi = fi}
        if(tasktypeId&&!x.tasktypeId){x.tasktypeId = tasktypeId}
        if(isDeleted){x.isDeleted = isDeleted}
        if(isNew){x.isNew = isNew}
        currentMap.set(id,x)
    }

    //console.log('after edit ' +  revCounter);
    lastIncrementUndoOrRedo = UNDO_STATE.none
}

export function addRedoTransaction(currentMap,id,st,fi,tasktypeId,isNew=null,isDeleted=null){
    currentMap.set(id,{st:st,fi:fi,tasktypeId:tasktypeId,isNew:isNew,isDeleted:isDeleted})
}

export function getRevisionMap(){
    console.log('REV INDEX: '+ revCounter +' size '+revisionMap.size);
    return revisionMap.get(revCounter)
}

export function canUndoRedo(){
   let canUndo = false
   let canRedo =false

    let redoDataExist =(revisionMap.size > 0 &&revisionMap.get(revCounter).redo.size>0)
    let undoDataExist =(revisionMap.size > 0 &&revisionMap.get(revCounter).undo.size>0)
    
    if(redoDataExist){
        if(lastIncrementUndoOrRedo==UNDO_STATE.redo){canRedo= revCounter<revisionMap.size-1}
        if(lastIncrementUndoOrRedo==UNDO_STATE.undo){canRedo= revCounter<revisionMap.size}
    }
    if(undoDataExist){
        if(lastIncrementUndoOrRedo!==UNDO_STATE.undo){canUndo= revCounter>=0}
        else {canUndo= revCounter>0 }
    }
    return {undo:canUndo,redo:canRedo}

}


function createUndoState() {
    const enumObject = {
    none: 0,
    undo: 1,
    redo: 2,
    };
    
    return Object.freeze(enumObject);
    }


    


