import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays } from '@/js/dn-helper.js';
import { createSchedule } from "@/model/dn-employee-schedule.js";

/**
 * @typedef {{adherenceOptions:{adherenceBase:number}; currentCC:{tagIds:number[]}; holidays:{ date: Date; }[]; generalEmpAppSettings:{weekStartDay:number}; taskTypes:import("@/model/dn-tasktype.js").TaskTypes}} PayrollReportData
 */

/**
 * @typedef {{numHoursWeeklyOvertime:number;weekendAsHoliday:boolean}} ReportOptions
 */

/**
 * @typedef {{id?:number;payrollRuleId?:number;workType:number|null;taskTypes?:number[];stMinute:number|null;stDay:number|null;fiMinute:number|null;fiDay:number|null;subtract:boolean|null;shiftBounds:boolean}} PayrollRuleDetailDto
 */

/**
 * @typedef {{id?:number;agreementId?:number;code:string;name:string;kind:number;weekLimitAbove:boolean|null;details?:PayrollRuleDetailDto[]}} PayrollRuleDto
 */

/**
 * @typedef {{id:number;name:string;tagId:number|null;payrollPeriod:number;payrollPeriodShiftBounds:boolean;payrollRules?:PayrollRuleDto[]}} AgreementDto
 */

/**
 * @typedef {{empid:string;empName:string;code:string;payrollName:string;amount:number;unit:string}} PayrollReportRow 
 */

/**
 * @typedef {{st:Date;numberOfDays:number}} TimeRange
 */

export const PAYROLL_RULE_KIND = createPayrollRuleKind();

function createPayrollRuleKind() {
  const e = {
    hours: 0,
    dayCount: 1,
    /** @type {(t:(arg0:string) => string) => {id:number;name:string}[]} */
    getOptions: null
  };

  e.getOptions = function (t) {
    return [dd(e.hours, t('settings.hours')), dd(e.dayCount, t('settings.day-count'))];
  }

  return Object.freeze(e);
}

export const PAYROLL_RULE_WORK_TYPE = createPayrollRuleWorkType();

function createPayrollRuleWorkType() {
  const e = {
    paidWork: 0,
    attendance: 1,
    taskTypes: 2,
  };

  return Object.freeze(e);
}

export const PAYROLL_RULE_DAY_KIND = createPayrollRuleDayKind();

function createPayrollRuleDayKind() {
  const e = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    holiday: 7,
    dayBeforeHoliday: 8,
    dayAfterHoliday: 9,
    /** @type {(t:(arg0:string) => string) => {id:number;name:string}[]} */
    getOptions: null
  };

  e.getOptions = function (t) {
    const result = []
    for (let i = 0; i < 7; i++) {
      const dt = new Date(2024, 0, 7 + i);
      result.push(dd(i, dt.toLocaleDateString('en-US', { weekday: 'long' })));
    }
    result.push(dd(e.holiday, t('settings.holiday')));
    result.push(dd(e.dayBeforeHoliday, t('settings.day-before-holiday')));
    result.push(dd(e.dayAfterHoliday, t('settings.day-after-holiday')));

    return result;
  }

  return Object.freeze(e);
}

export function getWeekLimitAboveOptions(t) {
  return [{ id: true, name: 'above' }, { id: false, name: 'below' }];
}

/**
 * 
 * @param {number} id
 * @param {string} name
 */
function dd(id, name) {
  return { id, name };
}

export class Agreements {
  constructor() {
    /** @private @type {Map<number, AgreementDto>} */
    this.byId = null;
    /** @private @type {Map<number, AgreementDto>} */
    this._byTagId = null;
    this.loadCounter = 0;
  }

  get byTagId() {
    if (this._byTagId === null && this.isLoaded) {
      /** @type {Map<number, AgreementDto>} */
      const byTagId = new Map();
      for (const dto of this.byId.values()) {
        byTagId.set(dto.tagId, dto);
      }
      this._byTagId = byTagId;
    }
    return this._byTagId;
  }

  get isLoaded() {
    return this.byId !== null;
  }

  get hasPayrollRules() {
    if (!this.isLoaded) { return undefined; }
    for (const agreement of this.byId.values()) {
      if (agreement.payrollRules.length > 0) {
        return true;
      }
    }
    return false;
  }

  /** @returns {AgreementDto[]} */
  asList() {
    if (this.byId) {
      return Array.from(this.byId.values());
    }
    return undefined;
  }

  /**
   * @param {number} id
   */
  async delete(id) {
    await fetchAndCheckJson('agreement/' + id, 'DELETE');
    if (this.byId) {
      this.byId.delete(id);
      this._byTagId = null;
    }
  }

  async load() {
    /** @type {AgreementDto[]} */
    const dtoList = await fetchAndCheckJson('agreement', 'GET');
    const byId = new Map();
    for (const dto of dtoList) {
      byId.set(dto.id, dto);
    }
    this.byId = byId;
    this._byTagId = null;
    this.loadCounter += 1;
  }

  /**
   * @param {import("@/model/dn-agreement-edit.js").SaveAgreementDto[]} agreementsToSave
   */
  async save(agreementsToSave) {
    await fetchAndCheckJson('agreement', 'POST', agreementsToSave);
    await this.load();
  }

  /**
   * @private
   * @param {number[]} tagIdList
   * @param {AgreementDto} defaultAgreement
   */
  getByTagId(tagIdList, defaultAgreement = undefined) {
    const byTagId = this.byTagId;
    for (const tagId of tagIdList) {
      const dto = byTagId.get(tagId);
      if (dto) { return dto; }
    }
    if (defaultAgreement === undefined) {
      defaultAgreement = this._byTagId.get(null);
      if (defaultAgreement === undefined) {
        defaultAgreement = null;
      }
    }

    return defaultAgreement;
  }

  /**
   * @param {{tagIds:number[]}} employee
   * @param {{tagIds:number[]}} cc
   */
  getPayrollPeriod(employee, cc) {
    const defaultAgreement = this.getByTagId(cc.tagIds);
    const agreement = this.getByTagId(employee.tagIds, defaultAgreement);
    return agreement ? agreement.payrollPeriod : 0;
  }

  /**
   * @param {TimeRange} timeRange
   * @param {import("@/model/dn-employee").Employee[]} employees
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   * @param {PayrollReportData} data
   * @param {ReportOptions} reportOptions
   */
  getPayrollReportData(timeRange, employees, scheduleTasks, data, reportOptions) {
    return this.getPayrollReportDataImpl(timeRange, employees, scheduleTasks, data, reportOptions).rows;
  }

  /**
   * @param {TimeRange} timeRange
   * @param {import("@/model/dn-employee").Employee[]} employees
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   * @param {PayrollReportData} data
   * @param {ReportOptions} reportOptions
   */
  getPayrollReportDataOneRow(timeRange, employees, scheduleTasks, data, reportOptions) {
    return this.getPayrollReportDataImpl(timeRange, employees, scheduleTasks, data, reportOptions).oneRowReport;
  }

  /**
   * @private
   * @param {TimeRange} timeRange
   * @param {import("@/model/dn-employee").Employee[]} employees
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   * @param {PayrollReportData} data
   * @param {ReportOptions} reportOptions
   */
  getPayrollReportDataImpl(timeRange, employees, scheduleTasks, data, reportOptions) {
    const cc = data.currentCC;
    const holidays = data.holidays;
    const taskTypes = data.taskTypes;
    const adherenceBase = data.adherenceOptions.adherenceBase;
    const weeklyLimit = reportOptions.numHoursWeeklyOvertime ? reportOptions.numHoursWeeklyOvertime : 40;
    const sortedHolidays = getSortedHolidays(timeRange, holidays, reportOptions.weekendAsHoliday);
    const timeRangeWeeks = splitTimeRangeInWeeks(data.generalEmpAppSettings.weekStartDay, timeRange);

    const defaultAgreement = this.getByTagId(cc.tagIds);
    /** @type {import("@/model/dn-employee").Employee[]} */
    const employeesWithAgreement = [];
    /** @type {Map<number, PayrollRuleCalcGroup[]>} */
    const payrollRuleGroupsById = new Map();
    /** @type {Map<number, PayrollRuleCalcGroup[]>} */
    const payrollRuleGroupsEmpId = new Map();

    for (const emp of employees) {
      const agreement = this.getByTagId(emp.tagIds, defaultAgreement);
      if (agreement) {
        employeesWithAgreement.push(emp);
        let groups = payrollRuleGroupsById.get(agreement.id);
        if (groups === undefined) {
          groups = [];
          for (const dto of agreement.payrollRules) {
            /** @type {PayrollRuleCalcGroup} */
            let group = undefined;
            if (dto.code.length > 0) {
              group = groups.find(x => x.code === dto.code && x.kind === dto.kind);
            }
            if (!group) {
              group = new PayrollRuleCalcGroup(dto);
              groups.push(group);
            }
            if (dto.weekLimitAbove === null) {
              const payrollRule = new PayrollRuleCalc(taskTypes.byId, dto, timeRange, agreement.payrollPeriodShiftBounds, weeklyLimit, sortedHolidays, adherenceBase);
              group.payrollRules.push(payrollRule);
            } else {
              for (const tr of timeRangeWeeks) {
                const payrollRule = new PayrollRuleCalc(taskTypes.byId, dto, tr, agreement.payrollPeriodShiftBounds, weeklyLimit, sortedHolidays, adherenceBase);
                group.payrollRules.push(payrollRule);
              }
            }
          }
          for (const group of groups) {
            group.calcName();
          }

          payrollRuleGroupsById.set(agreement.id, groups);
        }
        payrollRuleGroupsEmpId.set(emp.id, groups);
      }
    }

    /** @type {import("@/components/Reporting/TableReport.vue").ReportColumnDefinition[]} */
    const reportColumnDefinitions = [];
    for (const rules of payrollRuleGroupsById.values()) {
      for (const r of rules) {
        reportColumnDefinitions.push({ name: r.id.toString(), header: r.code, width: 50 });
      }
    }

    const schedules = createSchedule(timeRange, scheduleTasks, taskTypes, employeesWithAgreement, true);
    /** @type {PayrollReportRow[]} */
    const rows = [];
    /** @type {{empid:string;empName:string;[p:string]:any}[]} */
    const rows2 = [];
    /** @type {Map<number, {empid:string;empName:string;[p:string]:any}>} */
    const row2ByEmp = new Map();

    for (const employeeSchedule of schedules) {
      const groups = payrollRuleGroupsEmpId.get(employeeSchedule.emp.id);
      const empid = employeeSchedule.emp.empid;
      const empName = employeeSchedule.emp.name;
      for (const payrollRuleGroup of groups) {
        let amount = 0;
        for (const payrollRule of payrollRuleGroup.payrollRules) {
          amount += payrollRule.getAmount(employeeSchedule)
        }
        if (amount !== 0) {
          amount = Math.round(10000 * amount) / 10000;
          const code = payrollRuleGroup.code;
          const payrollName = payrollRuleGroup.name;
          const unit = payrollRuleGroup.unit;
          rows.push({ empid, empName, code, payrollName, amount, unit });
          let row2 = row2ByEmp.get(employeeSchedule.emp.id);
          if (row2 === undefined) {
            row2 = { empid, empName };
            row2ByEmp.set(employeeSchedule.emp.id, row2)
            rows2.push(row2)
          }
          row2[payrollRuleGroup.id] = amount;
        }
      }
    }

    return { rows, oneRowReport: { reportColumnDefinitions, rows: rows2 } };
  }
}

class PayrollRuleCalc {
  /**
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {PayrollRuleDto} payrollRule
   * @param {TimeRange} timeRange
   * @param {boolean} payrollPeriodShiftBounds
   * @param {number} weeklyLimit
   * @param {Date[]} sortedHolidays
   * @param {number} adherenceBase
   */
  constructor(taskTypeById, payrollRule, timeRange, payrollPeriodShiftBounds, weeklyLimit, sortedHolidays, adherenceBase) {
    /** @type {string} */
    this.code = payrollRule.code;
    /** @type {string} */
    this.name = payrollRule.name;
    /** @type {number} */
    this.weeklyLimit = weeklyLimit;
    /** @type {boolean} */
    this.weeklyLimitAbove = payrollRule.weekLimitAbove;
    const unitDays = payrollRule.kind === PAYROLL_RULE_KIND.dayCount;
    const useShiftBounds = payrollRule.kind === PAYROLL_RULE_KIND.dayCount || payrollPeriodShiftBounds;

    /** @type {PayrollRuleDetail[]} */
    this.details = [];
    for (const detail of payrollRule.details) {
      if (detail.shiftBounds) {
        this.details.push(new PayrollRuleShiftBound(detail, taskTypeById, timeRange,
          sortedHolidays, adherenceBase, unitDays));
      } else {
        this.details.push(new PayrollRuleTime(detail, taskTypeById, timeRange,
          sortedHolidays, adherenceBase, unitDays, useShiftBounds));
      }
    }
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getAmount(employeeSchedule) {
    let amount = 0;
    for (const d of this.details) {
      amount += d.getAmount(employeeSchedule);
    }
    if (this.weeklyLimitAbove === true) {
      amount = Math.max(0, amount - this.weeklyLimit);
    } else if (this.weeklyLimitAbove === false) {
      amount = Math.min(amount, this.weeklyLimit);
    }
    return amount;
  }
}

class PayrollRuleDetail {
  /**
   * @param {PayrollRuleDetailDto} payrollRule
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {TimeRange} timeRange
   * @param {boolean} unitDays
   * @param {number} adherenceBase
   */
  constructor(payrollRule, taskTypeById, timeRange, unitDays, adherenceBase) {
    /** @type {number} */
    this.adherenceBase = adherenceBase;
    /** @type {boolean} */
    this.calcAttendance = payrollRule.workType === PAYROLL_RULE_WORK_TYPE.attendance;
    /** @readonly @private @type {boolean} */
    this.subtract = payrollRule.subtract;
    /** @type {Map<number, Set<number>>} */
    this.taskTypesByKind = new Map();
    /** @type {boolean} */
    this.unitDays = unitDays;
    for (const taskTypeId of payrollRule.taskTypes) {
      const tt = taskTypeById.get(taskTypeId);
      let ttSet = this.taskTypesByKind.get(tt.kind);
      if (ttSet === undefined) {
        ttSet = new Set();
        this.taskTypesByKind.set(tt.kind, ttSet);
      }
      ttSet.add(tt.id);
    }
    /** @type {TimeRange} */
    this.timeRange = timeRange;
  }

  /**
   * @protected
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getInternalAmount(employeeSchedule) {
    return 0;
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getAmount(employeeSchedule) {
    let amount = this.getInternalAmount(employeeSchedule);
    if (this.subtract) {
      return -amount;
    }
    return amount;
  }
}

class PayrollRuleTime extends PayrollRuleDetail {
  /**
   * @param {PayrollRuleDetailDto} payrollRule
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {TimeRange} timeRange
   * @param {Date[]} sortedHolidays
   * @param {number} adherenceBase
   * @param {boolean} unitDays
   * @param {boolean} useShiftBounds
   */
  constructor(payrollRule, taskTypeById, timeRange, sortedHolidays, adherenceBase, unitDays, useShiftBounds) {
    super(payrollRule, taskTypeById, timeRange, unitDays, adherenceBase)
    const minutesPerDay = 24 * 60;

    /** @type {boolean} */
    this.useShiftBounds = useShiftBounds;
    /** @readonly @type {{st:Date;fi:Date}[]} */
    this.timeToRemove = [];
    if (!this.useShiftBounds) {
      this.addTimeToRemove(addDays(timeRange.st, -1000), timeRange.st);
      const fi = addDays(timeRange.st, timeRange.numberOfDays);
      this.addTimeToRemove(fi, addDays(fi, 1000));
    }
    if (payrollRule.stDay !== null && payrollRule.fiDay !== null) {
      let stMinute = payrollRule.stMinute !== null ? payrollRule.stMinute : 0;
      let fiMinute = payrollRule.fiMinute !== null ? payrollRule.fiMinute : minutesPerDay;

      if (payrollRule.stDay < 7) {
        let numberOfDays = timeRange.numberOfDays + 1;
        /** @type {Date} */
        let st = null;
        for (let i = -7; i < numberOfDays; i++) {
          const dt = addDays(timeRange.st, i);
          const day = dt.getDay();
          if (st === null && payrollRule.fiDay === day) {
            st = new Date(dt);
            st.setMinutes(fiMinute);
          }
          if (st !== null && payrollRule.stDay === day) {
            const fi = new Date(dt);
            fi.setMinutes(stMinute);
            if (st < fi) {
              this.addTimeToRemove(st, fi);
              st = null;
            }
          }
          if (st === null && payrollRule.fiDay === day) {
            st = new Date(dt);
            st.setMinutes(fiMinute);
          }
        }
        if (st !== null) {
          this.addTimeToRemove(st, addDays(timeRange.st, numberOfDays + 7));
        }
      } else {
        if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          stMinute -= minutesPerDay;
        } else if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          stMinute += minutesPerDay;
        }
        if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          fiMinute -= minutesPerDay;
        } else if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          fiMinute += minutesPerDay;
        }
        if (stMinute < fiMinute) {
          let st = addDays(timeRange.st, -7);
          let fi = null;
          for (const holiday of sortedHolidays) {
            fi = new Date(holiday);
            fi.setMinutes(stMinute);
            if (st < fi) {
              this.addTimeToRemove(st, fi);
            }
            st = new Date(holiday)
            st.setMinutes(fiMinute);
          }
          fi = addDays(timeRange.st, timeRange.numberOfDays + 7);
          this.addTimeToRemove(st, fi);
        }
      }
    } else if (payrollRule.stMinute !== null && payrollRule.fiMinute !== null) {
      let dt = addDays(timeRange.st, -1);
      let numberOfDays = timeRange.numberOfDays + 2;
      if (payrollRule.stMinute > 0) {
        const fi = new Date(dt);
        fi.setMinutes(payrollRule.stMinute);
        this.addTimeToRemove(dt, fi);
      }
      let stMinute = payrollRule.stMinute;
      let fiMinute = payrollRule.fiMinute;
      if (fiMinute <= stMinute) {
        dt = timeRange.st;
        numberOfDays -= 1;
      } else {
        stMinute += minutesPerDay;
      }
      for (let i = 0; i < numberOfDays; i++) {
        const st = new Date(dt);
        st.setMinutes(fiMinute);
        const fi = new Date(dt);
        fi.setMinutes(stMinute);
        this.addTimeToRemove(st, fi);
        dt = addDays(dt, 1);
      }
    }
  }

  /**
   * @private
   * @param {Date} st
   * @param {Date} fi
   */
  addTimeToRemove(st, fi) {
    if (st < fi) {
      this.timeToRemove.push({ st, fi });
    }
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getInternalAmount(employeeSchedule) {
    return employeeSchedule.getPayrollTime(this);
  }
}

class PayrollRuleShiftBound extends PayrollRuleDetail {
  /**
   * @param {PayrollRuleDetailDto} payrollRule
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {TimeRange} timeRange
   * @param {Date[]} sortedHolidays
   * @param {number} adherenceBase
   * @param {boolean} unitDays
   */
  constructor(payrollRule, taskTypeById, timeRange, sortedHolidays, adherenceBase, unitDays) {
    super(payrollRule, taskTypeById, timeRange, unitDays, adherenceBase)
    const minutesPerDay = 24 * 60;
    const timeRangeFi = addDays(timeRange.st, timeRange.numberOfDays);

    /** @type {{st:Date;fi:Date}[]} */
    this.timeIntervals = [];
    if (payrollRule.stDay !== null && payrollRule.fiDay !== null) {
      let stMinute = payrollRule.stMinute !== null ? payrollRule.stMinute : 0;
      let fiMinute = payrollRule.fiMinute !== null ? payrollRule.fiMinute : minutesPerDay;
      if (payrollRule.stDay < 7) {
        let numberOfDays = timeRange.numberOfDays + 1;
        /** @type {Date} */
        let st = null;
        for (let i = -7; i < numberOfDays; i++) {
          const dt = addDays(timeRange.st, i);
          const day = dt.getDay();
          if (st === null && payrollRule.stDay === day) {
            st = new Date(dt);
            st.setMinutes(stMinute);
            if (st < timeRange.st) { st = timeRange.st; }
          }
          if (st !== null && payrollRule.fiDay === day) {
            let fi = new Date(dt);
            fi.setMinutes(fiMinute);
            if (fi > timeRangeFi) { fi = timeRangeFi; }
            this.addtimeIntervals(st, fi);
            st = null;
          }
          if (st === null && payrollRule.stDay === day) {
            st = new Date(dt);
            st.setMinutes(stMinute);
            if (st < timeRange.st) { st = timeRange.st; }
          }
        }
        if (st !== null) {
          this.addtimeIntervals(st, timeRangeFi);
        }
      } else {
        if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          stMinute -= minutesPerDay;
        } else if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          stMinute += minutesPerDay;
        }
        if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          fiMinute -= minutesPerDay;
        } else if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          fiMinute += minutesPerDay;
        }
        if (stMinute < fiMinute) {
          /** @type {Date} */
          let st = null;
          /** @type {Date} */
          let fi = null;
          for (const holiday of sortedHolidays) {
            let newSt = new Date(holiday);
            newSt.setMinutes(stMinute);
            if (newSt < timeRange.st) { newSt = timeRange.st; }
            if (fi) {
              if (fi < newSt) {
                this.addtimeIntervals(st, fi);
                st = newSt;
              }
            } else {
              st = newSt;
            }
            fi = new Date(holiday);
            fi.setMinutes(fiMinute);
            if (fi > timeRangeFi) { fi = timeRangeFi; }
          }

          if (fi) {
            this.addtimeIntervals(st, fi);
          }
        }
      }
    } else if (payrollRule.stMinute !== null && payrollRule.fiMinute !== null && payrollRule.stMinute !== payrollRule.fiMinute) {
      const stMinute = payrollRule.stMinute;
      const fiMinute = payrollRule.fiMinute;
      const fiNextDay = fiMinute < stMinute;
      if (fiNextDay) {
        const fi = new Date(timeRange.st);
        fi.setMinutes(fiMinute);
        this.addtimeIntervals(timeRange.st, fi);
      }
      const lastIndex = timeRange.numberOfDays - 1;
      for (let i = 0; i < timeRange.numberOfDays; i++) {
        const st = addDays(timeRange.st, i);
        const fi = fiNextDay ? addDays(st, 1) : new Date(st);
        st.setMinutes(stMinute);
        if (!fiNextDay || lastIndex !== i) {
          fi.setMinutes(fiMinute);
        }
        this.addtimeIntervals(st, fi);
      }
    } else {
      this.addtimeIntervals(timeRange.st, timeRangeFi);
    }
  }

  /**
   * @private
   * @param {Date} st
   * @param {Date} fi
   */
  addtimeIntervals(st, fi) {
    if (st < fi) {
      this.timeIntervals.push({ st, fi });
    }
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getInternalAmount(employeeSchedule) {
    return employeeSchedule.getPayrollShiftBound(this);
  }
}

class PayrollRuleCalcGroup {
  /**
   * @param {PayrollRuleDto} payrollRule
   */
  constructor(payrollRule) {
    /** @readonly @type {number} */
    this.id = payrollRule.id;
    /** @readonly @type {string} */
    this.code = payrollRule.code;
    /** @type {string} */
    this.name = payrollRule.name;
    /** @readonly @type {number} */
    this.kind = payrollRule.kind;
    /** @readonly @type {string} */
    this.unit = payrollRule.kind === PAYROLL_RULE_KIND.dayCount ? 'days' : 'hours';
    /** @readonly @type {PayrollRuleCalc[]} */
    this.payrollRules = [];
  }

  calcName() {
    if (this.payrollRules.length <= 1) { return; }
    let name = this.name;
    for (const r of this.payrollRules) {
      if (name.length === 0 || r.name.startsWith(name)) { continue; }
      const length = Math.min(name.length, r.name.length)
      let count = 0;
      let newlength = 0;
      for (let i = 0; i < length; i++) {
        if (name[i] !== r.name[i]) { break; }
        if (name[i] === ' ') { newlength = count; }
        count += 1;
      }
      if (newlength !== length) {
        name = name.substring(0, newlength);
      }
    }

    if (name.length > 0) {
      this.name = name;
    } else {
      this.name = this.code;
    }
  }
}

/**
 * Get start time of holidays near the timerange
 * @param {{ st: Date; numberOfDays: number; }} timeRange
 * @param {{ date: Date; }[]} holidays
 * @param {boolean} weekendAsHoliday
 */
function getSortedHolidays(timeRange, holidays, weekendAsHoliday) {
  const st = addDays(timeRange.st, -1);
  const fi = addDays(timeRange.st, timeRange.numberOfDays + 1);
  /** @type {Date[]} */
  const sortedHolidays = [];
  for (const holiday of holidays) {
    const dt = holiday.date;
    if (dt >= st && dt < fi) {
      sortedHolidays.push(dt);
    }
  }
  if (weekendAsHoliday) {
    /** @type {Set<number>} */
    const uniqueDays = new Set();
    for (const h of sortedHolidays) {
      uniqueDays.add(h.getTime());
    }

    let dt = st;
    while (dt < fi) {
      const day = dt.getDay();
      if ((day === 0 || day === 6) && !uniqueDays.has(dt.getTime())) {
        sortedHolidays.push(dt);
      }
      dt = addDays(dt, 1);
    }
  }

  sortedHolidays.sort((a, b) => a.getTime() - b.getTime());
  return sortedHolidays;
}

/**
 * @param {number} weekStartDay
 * @param {TimeRange} timeRange
 */
function splitTimeRangeInWeeks(weekStartDay, timeRange) {
  /** @type {TimeRange[]} */
  const weeks = [];
  let st = timeRange.st;
  let day = timeRange.st.getDay();
  let numberOfDays = 0;
  for (let i = 0; i < timeRange.numberOfDays; i++) {
    day += 1;
    if (day > 6) { day = 0; }
    numberOfDays += 1;
    if (day === weekStartDay) {
      weeks.push({ st, numberOfDays });
      st = addDays(timeRange.st, i + 1);
      numberOfDays = 0;
    }
  }
  if (numberOfDays > 0) {
    weeks.push({ st, numberOfDays });
  }

  return weeks;
}